import { ReactNode } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { Button } from '@src/components/Button';
import { Heading } from '@src/components/Text';
import { View } from '@src/components/View';

// todo - eventually update Breadcrumbs component and reuse here
// <Breadcrumbs crumbs={[{ label: 'Organizations', to: 'organizations' }]} />
export function TableNameHeader(props: {
  children?: ReactNode;
  search?: boolean;
  style?: StyleProp<ViewStyle>;
  headingText: string;
  navAction: () => void;
  buttonText: string;
  testID?: string;
}) {
  return (
    <View
      row
      style={[
        {
          marginTop: -20,
          marginBottom: 30,
          paddingHorizontal: 20,
          paddingVertical: 14,
        },
        props.style,
      ]}
    >
      <View
        row
        flex={1}
        style={{
          justifyContent: 'space-between',
        }}
      >
        <Heading text={props.headingText} level={1} />
        {props.children}
      </View>
      <View flex={1} style={{ alignItems: 'flex-end' }}>
        <Button
          icon="plus"
          onPress={props.navAction}
          variant="contained"
          text={props.buttonText}
          testID={`${props.testID}_addNew`}
        />
      </View>
    </View>
  );
}
