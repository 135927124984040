import { useEffect, useState } from 'react';

import { parseJwt } from '@oui/lib/src/parseJwt';

import { getIdTokenResult } from '@src/lib/auth';

export function useIdTokenResult() {
  const [idTokenResult, setIdTokenResult] = useState({ token: '', claims: {} });
  useEffect(() => {
    getIdTokenResult().then((res) => {
      if (res) {
        try {
          const decoded = parseJwt(res);
          setIdTokenResult({
            token: res,
            ...decoded,
          });
        } catch (e) {
          setIdTokenResult({
            token: res,
            claims: {},
          });
        }
      }
    });
  }, []);
  return idTokenResult;
}
