import { GQLUUID } from '@oui/lib/src/types/scalars';

import { usePatientQuery } from '@src/screens/Patient.graphql.generated';

export function usePatient(
  patientID: GQLUUID,
  options?: Omit<Partial<Parameters<typeof usePatientQuery>[0]>, 'variables'>,
) {
  return usePatientQuery({
    variables: {
      patientID,
    },
    skip: !patientID,
    ...options,
  });
}
