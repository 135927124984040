import { ScrollView } from 'react-native';

import { ChecklistWithPersistence } from '@src/components/Checklist';

export function ClinicianMyStoryIntroduction() {
  return (
    <ScrollView>
      <ChecklistWithPersistence
        compositionTitle="MyStoryIntroductionChecklist"
        title="Introduce MyStory"
        steps={[
          {
            ID: 'what',
            title: 'What - Chance to share your story; Way to spot details that can help.',
            items: [],
          },
          {
            ID: 'why',
            title: 'Why - Learn from it. Understand warning signs.',
            items: [],
          },
          {
            ID: 'admin',
            title: 'Admin - Confidentiality. Taking notes.',
            items: [],
          },
          {
            ID: 'videos',
            title: 'Two Learn videos - Voiceover & Vignette',
            items: [],
          },
        ]}
      />
    </ScrollView>
  );
}
