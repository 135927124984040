// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePatientMutationVariables = Types.Exact<{
  patientID: Types.Scalars['UUID']['input'];
  data: Types.PatientUpdateInput;
}>;


export type UpdatePatientMutation = { readonly updatePatient: { readonly __typename: 'PatientProfile', readonly patient: { readonly __typename: 'Patient', readonly ID: CustomScalars.GQLUUID, readonly person: { readonly email?: string | null, readonly givenName?: string | null, readonly familyName?: string | null, readonly birthDate?: CustomScalars.GQLDate | null, readonly race: Types.UserRace, readonly genderIdentity: Types.UserGenderIdentity, readonly sexualOrientation: Types.UserSexualOrientation, readonly militaryStatus: Types.UserMilitaryStatus } } } };

export type AddContactPointForPatientMutationVariables = Types.Exact<{
  patientID: Types.Scalars['UUID']['input'];
  contactPoint: Types.ContactPointInput;
}>;


export type AddContactPointForPatientMutation = { readonly addContactPointForPatient: { readonly __typename: 'PatientProfile', readonly patient: { readonly __typename: 'Patient', readonly ID: CustomScalars.GQLUUID, readonly person: { readonly contactPoint: ReadonlyArray<{ readonly ID: CustomScalars.GQLUUID, readonly system?: string | null, readonly use?: string | null, readonly value?: string | null }> } } } };

export type AddAddressForPatientMutationVariables = Types.Exact<{
  patientID: Types.Scalars['UUID']['input'];
  address: Types.AddressInput;
}>;


export type AddAddressForPatientMutation = { readonly addAddressForPatient: { readonly __typename: 'PatientProfile', readonly patient: { readonly __typename: 'Patient', readonly ID: CustomScalars.GQLUUID, readonly person: { readonly address: ReadonlyArray<{ readonly use: Types.AddressUse, readonly line?: ReadonlyArray<string> | null, readonly city?: string | null, readonly state?: string | null, readonly postalCode?: string | null }> } } } };


export const UpdatePatientDocument = gql`
    mutation UpdatePatient($patientID: UUID!, $data: PatientUpdateInput!) {
  updatePatient(patientID: $patientID, update: $data) {
    __typename
    patient {
      __typename
      ID
      person {
        email
        givenName
        familyName
        birthDate
        race
        genderIdentity
        sexualOrientation
        militaryStatus
      }
    }
  }
}
    `;

/**
 * __useUpdatePatientMutation__
 *
 * To run a mutation, you first call `useUpdatePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientMutation, { data, loading, error }] = useUpdatePatientMutation({
 *   variables: {
 *      patientID: // value for 'patientID'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePatientMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePatientMutation, UpdatePatientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePatientMutation, UpdatePatientMutationVariables>(UpdatePatientDocument, options);
      }
export type UpdatePatientMutationHookResult = ReturnType<typeof useUpdatePatientMutation>;

export function performUpdatePatient(client: Apollo.ApolloClient<unknown>, variables: UpdatePatientMutationVariables, options?: Omit<Apollo.MutationOptions<UpdatePatientMutation, UpdatePatientMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<UpdatePatientMutation, UpdatePatientMutationVariables>({
    mutation: UpdatePatientDocument,
    variables,
    ...options,
  });
}

export const AddContactPointForPatientDocument = gql`
    mutation AddContactPointForPatient($patientID: UUID!, $contactPoint: ContactPointInput!) {
  addContactPointForPatient(patientID: $patientID, contactPoint: [$contactPoint]) {
    __typename
    patient {
      __typename
      ID
      person {
        contactPoint {
          ID
          system
          use
          value
        }
      }
    }
  }
}
    `;

/**
 * __useAddContactPointForPatientMutation__
 *
 * To run a mutation, you first call `useAddContactPointForPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContactPointForPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContactPointForPatientMutation, { data, loading, error }] = useAddContactPointForPatientMutation({
 *   variables: {
 *      patientID: // value for 'patientID'
 *      contactPoint: // value for 'contactPoint'
 *   },
 * });
 */
export function useAddContactPointForPatientMutation(baseOptions?: Apollo.MutationHookOptions<AddContactPointForPatientMutation, AddContactPointForPatientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddContactPointForPatientMutation, AddContactPointForPatientMutationVariables>(AddContactPointForPatientDocument, options);
      }
export type AddContactPointForPatientMutationHookResult = ReturnType<typeof useAddContactPointForPatientMutation>;

export function performAddContactPointForPatient(client: Apollo.ApolloClient<unknown>, variables: AddContactPointForPatientMutationVariables, options?: Omit<Apollo.MutationOptions<AddContactPointForPatientMutation, AddContactPointForPatientMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<AddContactPointForPatientMutation, AddContactPointForPatientMutationVariables>({
    mutation: AddContactPointForPatientDocument,
    variables,
    ...options,
  });
}

export const AddAddressForPatientDocument = gql`
    mutation AddAddressForPatient($patientID: UUID!, $address: AddressInput!) {
  addAddressForPatient(patientID: $patientID, address: [$address]) {
    __typename
    patient {
      __typename
      ID
      person {
        address {
          use
          line
          city
          state
          postalCode
        }
      }
    }
  }
}
    `;

/**
 * __useAddAddressForPatientMutation__
 *
 * To run a mutation, you first call `useAddAddressForPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAddressForPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAddressForPatientMutation, { data, loading, error }] = useAddAddressForPatientMutation({
 *   variables: {
 *      patientID: // value for 'patientID'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useAddAddressForPatientMutation(baseOptions?: Apollo.MutationHookOptions<AddAddressForPatientMutation, AddAddressForPatientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAddressForPatientMutation, AddAddressForPatientMutationVariables>(AddAddressForPatientDocument, options);
      }
export type AddAddressForPatientMutationHookResult = ReturnType<typeof useAddAddressForPatientMutation>;

export function performAddAddressForPatient(client: Apollo.ApolloClient<unknown>, variables: AddAddressForPatientMutationVariables, options?: Omit<Apollo.MutationOptions<AddAddressForPatientMutation, AddAddressForPatientMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<AddAddressForPatientMutation, AddAddressForPatientMutationVariables>({
    mutation: AddAddressForPatientDocument,
    variables,
    ...options,
  });
}
