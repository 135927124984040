import {
  PatientGenderIdentity,
  PatientMilitaryStatus,
  PatientRace,
  PatientSexualOrientation,
} from '@oui/lib/src/types/graphql.generated';

import { getPickerItemsFromEnum } from '@src/lib/getPickerItemsFromEnum';

export const RACE_V2 = getPickerItemsFromEnum(PatientRace);
export const GENDER_IDENTITY_V2 = getPickerItemsFromEnum(PatientGenderIdentity);
export const SEXUAL_ORIENTATION_V2 = getPickerItemsFromEnum(PatientSexualOrientation);
export const MILITARY_STATUS_V2 = getPickerItemsFromEnum(PatientMilitaryStatus);
