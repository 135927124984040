import { GQLUUID } from '@oui/lib/src/types/scalars';

import { Button } from '@src/components/Button';
import {
  PatientCareTeamQuery,
  useAddPractitionerToCareTeamMutation,
  usePatientCareTeamQuery,
  useRemovePractitionerFromCareTeamMutation,
  useSetCareTeamPrimaryPractitionerMutation,
} from '@src/components/PatientSectionCareTeam.graphql.generated';
import { ListItemStyle, StaffTable, TableCell } from '@src/components/StaffTable';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';

export function PatientSectionCareTeam({ patientID }: { patientID: GQLUUID }) {
  const { data, loading } = usePatientCareTeamQuery({
    variables: { patientID },
  });
  const [addPractitionerToCareTeam] = useAddPractitionerToCareTeamMutation();
  const [removePractitionerFromCareTeam] = useRemovePractitionerFromCareTeamMutation();
  const [setCareTeamPrimaryPractitioner] = useSetCareTeamPrimaryPractitionerMutation();

  const practitioners = [
    ...((data?.careTeamByPatientID?.organizations
      ?.map((o) => o.practitioners!)
      .flat() as PatientCareTeamQuery['careTeamByPatientID']['practitioners']) ?? []),
  ].sort((a, b) => {
    const aStr = a.person.givenName ?? a.person.familyName ?? '';
    const bStr = b.person.givenName ?? b.person.familyName ?? '';
    if (aStr === bStr) return 0;
    return aStr < bStr ? -1 : 1;
  });
  const primaryID = data?.careTeamByPatientID?.primaryPractitioner?.ID;

  return (
    <StaffTable
      keyExtractor={(item) => item.ID}
      loading={loading}
      columns={[{ label: 'name', grow: 1 }, { label: 'Primary?', grow: 1 }, 'Care team']}
      data={practitioners}
      renderItem={({ item }) => {
        const isPrimary = primaryID === item.ID;
        const isOnCareTeam = data?.careTeamByPatientID.practitioners.find((p) => p.ID === item.ID);
        return (
          <View style={[ListItemStyle]}>
            <TableCell>
              <Text text={[item.person.givenName, item.person.familyName].join(' ')} />
            </TableCell>
            <TableCell grow={1}>
              <Text text={primaryID === item.ID ? 'Yes ' : 'No '} />
              {isPrimary ? null : (
                <Button
                  variant="text"
                  text="Choose"
                  onPress={() => {
                    return setCareTeamPrimaryPractitioner({
                      variables: {
                        practitionerID: item.ID,
                        careTeamID: data!.careTeamByPatientID.ID,
                      },
                    });
                  }}
                />
              )}
            </TableCell>
            <TableCell grow={1}>
              {isOnCareTeam ? (
                <Button
                  variant="text"
                  text="Remove"
                  icon="minus"
                  onPress={() => {
                    return removePractitionerFromCareTeam({
                      variables: {
                        practitionerID: item.ID,
                        careTeamID: data!.careTeamByPatientID.ID,
                      },
                    });
                  }}
                />
              ) : (
                <Button
                  variant="text"
                  text="Add"
                  icon="plus"
                  onPress={() => {
                    return addPractitionerToCareTeam({
                      variables: {
                        practitionerID: item.ID,
                        careTeamID: data!.careTeamByPatientID.ID,
                      },
                    });
                  }}
                />
              )}
            </TableCell>
          </View>
        );
      }}
    />
  );
}
