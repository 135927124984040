// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientForMyStoryMyPlanQueryVariables = Types.Exact<{
  patientID: Types.Scalars['UUID']['input'];
}>;


export type PatientForMyStoryMyPlanQuery = { readonly getPatient?: { readonly __typename: 'PatientProfile', readonly patient: { readonly __typename: 'Patient', readonly ID: CustomScalars.GQLUUID, readonly productVariant: Types.ProductVariant, readonly productVersion: Types.ProductVersion, readonly onboardingVariant: Types.OnboardingVariant, readonly person: { readonly givenName?: string | null, readonly familyName?: string | null } } } | null };


export const PatientForMyStoryMyPlanDocument = gql`
    query PatientForMyStoryMyPlan($patientID: UUID!) {
  getPatient: patientByPatientID(patientID: $patientID) {
    __typename
    patient {
      __typename
      ID
      productVariant
      productVersion
      onboardingVariant
      person {
        givenName
        familyName
      }
    }
  }
}
    `;

/**
 * __usePatientForMyStoryMyPlanQuery__
 *
 * To run a query within a React component, call `usePatientForMyStoryMyPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientForMyStoryMyPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientForMyStoryMyPlanQuery({
 *   variables: {
 *      patientID: // value for 'patientID'
 *   },
 * });
 */
export function usePatientForMyStoryMyPlanQuery(baseOptions: Apollo.QueryHookOptions<PatientForMyStoryMyPlanQuery, PatientForMyStoryMyPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientForMyStoryMyPlanQuery, PatientForMyStoryMyPlanQueryVariables>(PatientForMyStoryMyPlanDocument, options);
      }
export function usePatientForMyStoryMyPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientForMyStoryMyPlanQuery, PatientForMyStoryMyPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientForMyStoryMyPlanQuery, PatientForMyStoryMyPlanQueryVariables>(PatientForMyStoryMyPlanDocument, options);
        }
export type PatientForMyStoryMyPlanQueryHookResult = ReturnType<typeof usePatientForMyStoryMyPlanQuery>;
export type PatientForMyStoryMyPlanLazyQueryHookResult = ReturnType<typeof usePatientForMyStoryMyPlanLazyQuery>;

export function queryPatientForMyStoryMyPlan(client: Apollo.ApolloClient<unknown>, variables: PatientForMyStoryMyPlanQueryVariables, options?: Omit<Apollo.QueryOptions<PatientForMyStoryMyPlanQuery, PatientForMyStoryMyPlanQueryVariables>, "query" | 'variables'>) {
  return client.query<PatientForMyStoryMyPlanQuery, PatientForMyStoryMyPlanQueryVariables>({
    query: PatientForMyStoryMyPlanDocument,
    variables,
    ...options,
  });
}
