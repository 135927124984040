/* eslint-disable @typescript-eslint/no-explicit-any */

import unique from 'lodash/uniq';
import { ReactNode, createElement, useState } from 'react';
// @ts-ignore
import { renderToStaticMarkup } from 'react-dom/server';
import Inky, { Center, Container, Wrapper as InkyWrapper, Spacer } from 'react-inky';
import { ScrollView } from 'react-native';

import { webDownload } from '@oui/app-core/src/lib/webDownload';

import { Button as AppButton } from '@src/components/Button';
import Checkbox from '@src/components/Checkbox';
import { Text as AppText } from '@src/components/Text';
import { View } from '@src/components/View';
import { useDailyState } from '@src/hooks/useDailyState';
import { Color } from '@src/styles';

const HEADER_LOGO_URL = 'https://storage.googleapis.com/asset.oui.dev/email/Full_logo.png';
const CAPS_HEADER_LOGO_URL = 'https://storage.googleapis.com/asset.oui.dev/email/CAPS_logo.png';
const PLAY_STORE_LOGO_URL =
  'https://storage.googleapis.com/asset.oui.dev/email/google-play-badge.png';
const APP_STORE_LOGO_URL = 'https://storage.googleapis.com/asset.oui.dev/email/app-store-badge.png';

function Wrapper(props: any) {
  return <InkyWrapper align="left" {...props} />;
}

function Button({ align = 'left', href, text }: { href: string; text: string; align?: string }) {
  return (
    <Wrapper align={align}>
      <Link
        href={href}
        style={{
          textDecoration: 'none',
        }}
      >
        <Wrapper
          className="button"
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
            paddingBottom: 10,
            backgroundColor: Color.styleGuide.LogoCyan,
            borderRadius: 28,
          }}
        >
          <Text style={{ color: 'white', fontWeight: '600' }}>{text}</Text>
        </Wrapper>
      </Link>
    </Wrapper>
  );
}

function Text({ style, ...props }: any) {
  return (
    <Wrapper
      style={{
        fontSize: '17px',
        lineHeight: '23px',
        color: '#242226',
        fontFamily: '"Open Sans", Helvetica, sans-serif',
        width: '100%',
        ...style,
      }}
      {...props}
    />
  );
}

function Heading({ style, ...props }: any) {
  return (
    <>
      <Text
        style={{
          fontSize: '21px',
          fontWeight: '600',
          ...style,
        }}
        {...props}
      />
      <Spacer size={10} />
    </>
  );
}

function Link({ style, children, href }: { style?: any; children: ReactNode; href: string }) {
  return (
    <a
      rel="noreferrer noopener"
      target="_blank"
      href={href}
      style={{
        fontSize: 'inherit',
        lineHeight: 'inherit',
        color: 'inherit',
        fontFamily: 'inherit',
        ...style,
      }}
    >
      {children}
    </a>
  );
}

function EmailWrapper({ children }: { children: ReactNode }) {
  return (
    <Inky>
      <Inky.Head>
        <link
          href="https://fonts.googleapis.com/css?family=Open%20Sans"
          rel="stylesheet"
          type="text/css"
        />
        <style>{`
@media screen and (max-width: 596px) {
  .outer {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
          `}</style>
      </Inky.Head>
      <Inky.Body>{children}</Inky.Body>
    </Inky>
  );
}

function EmailInner({ caps, children }: { caps?: boolean; children: ReactNode }) {
  return (
    <Container>
      <Wrapper
        style={{ maxWidth: 600, backgroundColor: Color.grayBackground, padding: 30 }}
        className="outer"
      >
        <Center>
          <img
            src={caps ? CAPS_HEADER_LOGO_URL : HEADER_LOGO_URL}
            width={246 / 2}
            height={53 / 2}
          />
        </Center>
        <Wrapper
          style={{
            marginTop: 30,
            marginBottom: 30,
            backgroundColor: 'white',
            borderRadius: 30,
            paddingBottom: 40,
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 40,
          }}
        >
          {children}
        </Wrapper>
        <Center>
          <Text
            style={{
              textAlign: 'center',
              color: '#6A6A7C',
              fontSize: '13px',
              lineHeight: '18px',
            }}
          >
            Oui Therapeutics, Inc.
            <br />
            4 Science Park, New Haven, CT 06511
            <br />
            <br />
            We keep your personal information secure in accordance
            <Spacer size={0} sizeSm={1} />
            with our{' '}
            <Link href="https://about.oui.health/terms-and-privacy">
              Terms of Service & Privacy Policy
            </Link>
            {false ? (
              <>
                <br />
                <br />
                <Link href="https://oui.health/preview">View in browser</Link> |{' '}
                <Link href="%unsubscribe_url%">Unsubscribe</Link> from our mailing lists
              </>
            ) : null}
          </Text>
        </Center>
      </Wrapper>
    </Container>
  );
}

function PatientInvite() {
  return (
    <EmailInner>
      <Wrapper>
        <Heading>Hi {'{{firstName}}'},</Heading>
        <Spacer size={20} />
        <Text>
          {'{{clinicianFirstName}} from {{site}}'} has activated your account on Aviva. Get started
          below:
        </Text>
      </Wrapper>
      <Spacer size={20} />
      <Wrapper style={{ marginBottom: 10 }}>
        <Text style={{ fontSize: '21px', lineHeight: '28px', fontWeight: '600' }}>
          Download the Aviva App
        </Text>
      </Wrapper>
      <Wrapper>
        <Link href="{{setPasswordUrl}}">
          <img
            src={PLAY_STORE_LOGO_URL}
            width={134}
            height={40}
            style={{ display: 'inline', marginRight: 10, marginBottom: 10 }}
          />
        </Link>
        <Link href="{{setPasswordUrl}}">
          <img
            src={APP_STORE_LOGO_URL}
            width={120}
            height={40}
            style={{ display: 'inline', marginRight: 10, marginBottom: 10 }}
          />
        </Link>
      </Wrapper>
    </EmailInner>
  );
}

function StaffInvite() {
  return (
    <EmailInner>
      <Wrapper>
        <Heading>Hi {'{{firstName}}'},</Heading>
        <Spacer size={20} />
        <Text>
          Your Aviva account is now activated for {'{{site}}'}. Next step, please set a password.
        </Text>
        <Spacer size={20} />
      </Wrapper>
      <Wrapper>
        <Button href="{{setPasswordUrl}}" text="Set a password" />
      </Wrapper>
      <Wrapper>
        <Spacer size={20} />
        <Text style={{ fontSize: '13px' }}>
          Button not working? Copy and paste this link into your browser:{'\n'}
          {`{{setPasswordUrl}}`}
        </Text>
      </Wrapper>
    </EmailInner>
  );
}

function SafetyPlan() {
  return (
    <EmailInner>
      <Wrapper>
        <Heading>Hi {'{{firstName}}'},</Heading>
        <Spacer size={10} />
        <Text>
          {'{{patientFullName}}'} is using an app called Aviva to help keep them safe. Through the
          app, {`they've`} developed a plan to help remove items from their access that they might
          use to hurt themselves.
        </Text>
        <Spacer size={10} />
        <Text>
          {'{{patientFirstName}}'} listed you as someone they wanted to share this plan with. The
          hope is that you will be able to help them implement the plan.
        </Text>
        <Spacer size={10} />
        <Text>
          It is a sign of great trust that Leng has chosen to share their plan with you. We thank
          you very much for everything you do to help keep them safe.
        </Text>
      </Wrapper>
      <Spacer size={20} />
      <Button href="https://oui.health" text="See {{patientFirstName}}'s Plan" align="center" />
    </EmailInner>
  );
}

function ResetPassword() {
  return (
    <EmailInner>
      <Wrapper>
        <Heading>Hi {'{{firstName}}'},</Heading>
        <Text>
          {`Forgot your password for Aviva? No worries. Let's set a new password for your account.`}
        </Text>
        <Spacer size={10} />
        <Button href="{{passwordResetUrl}}" text="Reset your password" align="center" />
        <Spacer size={20} />
        <Text style={{ fontSize: '13px' }}>
          Button not working? Copy and paste this link into your browser:{'\n'}
          {`{{passwordResetUrl}}`}
        </Text>
        <Spacer size={20} />
        <Text style={{ fontSize: '13px' }}>
          If you did not request a password reset, please ignore this email. Your password will not
          change until you create a new password.
        </Text>
      </Wrapper>
    </EmailInner>
  );
}

function PatientReinviteSelf() {
  return (
    <EmailInner>
      <Wrapper>
        <Heading>Hi {'{{firstName}}'},</Heading>
        <Text>
          Your Aviva account is almost ready. Set your password to get started with the app.
        </Text>
        <Spacer size={10} />
        <Button href="{{setPasswordUrl}}" text="Set your password" align="center" />
        <Spacer size={20} />
        <Text style={{ fontSize: '13px' }}>
          Button not working? Copy and paste this link into your browser:{'\n'}
          {`{{setPasswordUrl}}`}
        </Text>
      </Wrapper>
    </EmailInner>
  );
}

function ResetPasswordSuccess() {
  return (
    <EmailInner>
      <Wrapper>
        <Heading>Hi {'{{firstName}}'},</Heading>
        <Text>You have successfully set the password for your Aviva account.</Text>
        <Spacer size={10} />
        <Button href="{{continueUrl}}" text="Continue to Aviva app" align="center" />
      </Wrapper>
    </EmailInner>
  );
}

function CapsResultsPdf() {
  return (
    <EmailInner caps>
      <Wrapper>
        <Text>Hi,</Text>
        <Spacer size={10} />
        <Text>
          We{"'"}ve attached the CAPS suicide screening and planning documents you requested.
        </Text>
        <Spacer size={10} />
        <Text>Documents for:</Text>
        <Text style={{ fontWeight: '600' }}>{'{{patientID}}'}</Text>
        <Text style={{ fontWeight: '600' }}>{'{{clinicianName}}'}</Text>
      </Wrapper>
    </EmailInner>
  );
}

function CapsResultsTxt() {
  return (
    <EmailInner caps>
      <Wrapper>
        <Text>Hi,</Text>
        <Spacer size={10} />
        <Text>
          We{"'"}ve pasted the CAPS suicide screening and planning documents you requested below.
        </Text>
        <Spacer size={10} />
        <Text>For:</Text>
        <Text style={{ fontWeight: '600' }}>{'{{patientID}}'}</Text>
        <Text style={{ fontWeight: '600' }}>{'{{clinicianName}}'}</Text>
        <Spacer size={10} />
        <Text>Document:</Text>
        {/* NOTE using triple brace to allow <br /> */}
        <Text style={{ fontWeight: '600' }}>{'{{{document}}}'}</Text>
      </Wrapper>
    </EmailInner>
  );
}

function GenericEmail() {
  return (
    <EmailInner>
      <Wrapper>
        {/* NOTE using triple brace to allow <br /> */}
        <Text>{'{{{text}}}'}</Text>
      </Wrapper>
    </EmailInner>
  );
}

export const TEMPLATE_MAP = {
  invite_patient: PatientInvite,
  reinvite_self: PatientReinviteSelf,
  invite_staff: StaffInvite,
  safety_plan: SafetyPlan,
  reset_password: ResetPassword,
  reset_password_success: ResetPasswordSuccess,
  caps_results_pdf: CapsResultsPdf,
  caps_results_txt: CapsResultsTxt,
  generic: GenericEmail,
};

function emailTemplateAndPreview(key: keyof typeof TEMPLATE_MAP, fallback?: () => JSX.Element) {
  const preview = createElement(TEMPLATE_MAP[key] || fallback);
  const template = renderToStaticMarkup(<EmailWrapper>{preview}</EmailWrapper>);
  return { preview, template };
}

export function renderEmailTemplate(key: keyof typeof TEMPLATE_MAP, fallback?: () => JSX.Element) {
  return emailTemplateAndPreview(key, fallback).template;
}

export default function Emails() {
  const [showCode, setShowCode] = useState(false);
  const [template, setTemplate] = useDailyState<keyof typeof TEMPLATE_MAP>(
    'email-template',
    Object.keys(TEMPLATE_MAP)[0] as keyof typeof TEMPLATE_MAP,
  );

  const { preview, template: htmlString } = emailTemplateAndPreview(template, PatientInvite);

  return (
    <View row flex={1} style={{ alignItems: 'stretch' }}>
      <View style={{ height: '100%', padding: 10 }} spacing={8}>
        {Object.keys(TEMPLATE_MAP).map((key) => {
          return (
            <AppButton
              key={key}
              onPress={() => setTemplate(key as keyof typeof TEMPLATE_MAP)}
              text={key}
              variant={template === key ? 'solid' : 'contained'}
            />
          );
        })}
        <View style={{ marginTop: 20 }}>
          <Checkbox label="Show HTML" value={showCode} onChangeValue={setShowCode} horizontal />
        </View>
        <View flex={1} />
        <AppButton
          text="Export Templates"
          onPress={() => {
            const payload = Object.entries(TEMPLATE_MAP).reduce<{ [key: string]: string }>(
              (carry, [key, value]) => {
                const { template: output } = emailTemplateAndPreview(
                  key as keyof typeof TEMPLATE_MAP,
                );
                carry[key] = output;
                return carry;
              },
              {},
            );
            webDownload(payload, 'emails.json');
          }}
        />
      </View>
      <ScrollView contentContainerStyle={{ paddingHorizontal: 20 }}>
        {showCode ? <textarea value={htmlString} style={{ padding: 20 }} rows={100} /> : preview}
      </ScrollView>
      <View style={{ height: '100%', width: 200, padding: 10 }} spacing={8}>
        <AppText text="Variables" weight="bold" />
        {unique(htmlString.match(/{{[a-zA-Z_]*}}/g) || []).map((variable) => (
          <AppText text={variable.replace(/[{}]/g, '')} key={variable} />
        ))}
      </View>
    </View>
  );
}
