import { useCallback, useState } from 'react';

import { CrisisTimeline } from '@oui/myplan/src/components/CrisisTimeline';

import { ActivityIndicator } from '@src/components/ActivityIndicator';
import { Button } from '@src/components/Button';
import { ChecklistWithPersistence } from '@src/components/Checklist';
import { Divider } from '@src/components/Divider';
import { OldHeading } from '@src/components/Text';
import { View } from '@src/components/View';
import { useMyStoryMyPlanCompositionSections } from '@src/hooks/useComposition';
import { useTheme } from '@src/styles';

function MyStoryTimelineChecklist() {
  return (
    <ChecklistWithPersistence
      compositionTitle="MyStoryTimelineChecklist"
      title="MyStory Prompts"
      steps={[
        {
          ID: 'what-and-when',
          title: 'What happened & where?',
          items: [],
        },
        {
          ID: 'who',
          title: 'Who was there & why?',
          items: [],
        },
        {
          ID: 'thoughts-and-feelings',
          title: 'What do you remember feeling and thinking?',
          items: [],
        },
        {
          ID: 'senses',
          title: 'Any smells, sounds or sights?',
          items: [],
        },
      ]}
    />
  );
}

function TimelineReviewChecklist() {
  return (
    <ChecklistWithPersistence
      compositionTitle="TimelineReviewChecklist"
      title="Summarize patient timeline"
      steps={[
        {
          ID: 'order',
          title: 'Order is correct',
          items: [],
        },
        {
          ID: 'complete',
          title: 'Nothing has been left out',
          items: [],
        },
      ]}
    />
  );
}

function RiskCurveIntroductionChecklist() {
  return (
    <ChecklistWithPersistence
      compositionTitle="RiskCurveIntroductionChecklist"
      title="Risk Curve Introduction"
      steps={[
        {
          ID: 'risk-lifecycle',
          title: 'Suicide risk rises, peaks, and falls',
          items: [],
        },
        {
          ID: 'curve',
          title: 'Curve is a framework to understand suicide',
          items: [],
        },
        {
          ID: 'time-vs-risk',
          title: 'Key: with time, risk will fall. There is hope',
          items: [],
        },
        {
          ID: 'videos',
          title: 'Two Learn videos - Voiceover & Vignette',
          items: [],
        },
      ]}
    />
  );
}

function RiskCurveReviewChecklist() {
  return (
    <ChecklistWithPersistence
      compositionTitle="RiskCurveReviewChecklist"
      title="Plotting MyStory"
      steps={[
        {
          ID: 'plotting',
          title: 'Plotting helps you see when and how your risk rises',
          items: [],
        },
        {
          ID: 'peak',
          title: 'Can see when risk was highest',
          items: [],
        },
        {
          ID: 'warning-signs',
          title: 'Can identify the warning signs',
          items: [],
        },
      ]}
    />
  );
}

export function ClinicianMyStoryTimeline() {
  const { data, update } = useMyStoryMyPlanCompositionSections();
  const [showTimeline, setShowTimeline] = useState(true);
  const { Color } = useTheme();

  const updateCrisisTimeline = useCallback(
    (newTimeline: NonNullable<typeof data>['CRISIS_TIMELINE']) => {
      update({ CRISIS_TIMELINE: newTimeline });
    },
    [update],
  );

  if (!data?.CRISIS_TIMELINE) return <ActivityIndicator />;

  const isTimelineIntro = data.APP_STATE.currentStep === 'MYSTORY__TIMELINE';
  const isTimelineReview = (
    ['MYSTORY__TIMELINE_REVIEW', 'MYSTORY__TIMELINE_FINAL'] as Array<
      (typeof data)['APP_STATE']['currentStep']
    >
  ).includes(data.APP_STATE.currentStep);
  const showRiskColor = (
    ['RISK_CURVE__REVIEW', 'RISK_CURVE__INTRODUCTION'] as Array<
      (typeof data)['APP_STATE']['currentStep']
    >
  ).includes(data.APP_STATE.currentStep);
  const isRiskCurveIntro = data.APP_STATE.currentStep === 'RISK_CURVE__INTRODUCTION';

  return (
    <View testID="ClinicianMyStoryTimeline">
      <View>
        <View
          row
          style={{ paddingHorizontal: 32, paddingTop: 20, justifyContent: 'space-between' }}
        >
          <OldHeading text="Suicide Crisis Timeline" testID="ClinicianMyStoryTimeline_heading" />
          <Button
            color={Color.styleGuide.Gray4}
            text={showTimeline ? 'Hide' : 'Show'}
            iconRight={showTimeline ? 'caret-up' : 'caret-down'}
            onPress={() => setShowTimeline(!showTimeline)}
            variant="text"
            iconSize={10}
            textStyle={{ fontSize: 15, fontFamily: 'OpenSansRegular' }}
          />
        </View>
        {showTimeline ? (
          <CrisisTimeline
            onChangeValue={updateCrisisTimeline}
            value={data.CRISIS_TIMELINE}
            showRiskColor={showRiskColor}
          />
        ) : null}
      </View>
      <Divider />
      {isTimelineIntro ? (
        <MyStoryTimelineChecklist />
      ) : isTimelineReview ? (
        <TimelineReviewChecklist />
      ) : isRiskCurveIntro ? (
        <RiskCurveIntroductionChecklist />
      ) : (
        <RiskCurveReviewChecklist />
      )}
    </View>
  );
}
