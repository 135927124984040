import { useState } from 'react';
import { ScrollView } from 'react-native';

import { ActivityIndicator } from '@src/components/ActivityIndicator';
import { Button } from '@src/components/Button';
import { ClinicianMyPlanForm } from '@src/components/ClinicianMyPlanForm';
import { OldHeading } from '@src/components/Text';
import { View } from '@src/components/View';
import {
  useMyStoryMyPlanCompositionSectionSubscriptionData,
  useMyStoryMyPlanCompositionSections,
} from '@src/hooks/useComposition';
import { useTheme } from '@src/styles';

export function ClinicianMyPlanReview() {
  const { update } = useMyStoryMyPlanCompositionSections();
  const { data } = useMyStoryMyPlanCompositionSectionSubscriptionData();
  const [showForm, setShowForm] = useState(true);
  const { Color } = useTheme();

  if (!data) return <ActivityIndicator />;

  return (
    <ScrollView testID="ClinicianMyPlanReview">
      <View>
        <View
          row
          style={{ paddingHorizontal: 24, paddingTop: 20, justifyContent: 'space-between' }}
        >
          <OldHeading text="MyPlan" />
          <Button
            color={Color.styleGuide.Gray4}
            text={showForm ? 'Hide' : 'Show'}
            iconRight={showForm ? 'caret-up' : 'caret-down'}
            onPress={() => setShowForm(!showForm)}
            variant="text"
            iconSize={10}
            textStyle={{ fontSize: 15, fontFamily: 'OpenSansRegular' }}
          />
        </View>
        {showForm ? <ClinicianMyPlanForm value={data} onChangeValue={update} /> : null}
      </View>
    </ScrollView>
  );
}
