import { StackActionType, useNavigation } from '@react-navigation/native';
import { ComponentProps, ReactNode } from 'react';
import {
  FlatList,
  FlatListProps,
  Platform,
  StyleProp,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';

import { ActivityIndicator } from '@src/components/ActivityIndicator';
import { Icon } from '@src/components/Icon';
import { LinkButton } from '@src/components/LinkButton';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { useTheme } from '@src/styles';

export function TableCellDivider() {
  const { Color } = useTheme();
  return (
    <View
      style={{
        width: 2,
        backgroundColor: Color.grayBackground,
        marginVertical: -8,
      }}
    />
  );
}

export function TableCell({
  initialWidth = 50,
  grow = 1,
  children,
  style,
}: {
  initialWidth?: number | 'auto';
  grow?: number;
  children?: ReactNode;
  style?: StyleProp<ViewStyle>;
}) {
  return (
    <View
      row
      style={[
        {
          alignSelf: 'center',
          height: '100%',
          flexBasis: initialWidth,
          flexGrow: grow,
          padding: 8,
        },
        style,
      ]}
    >
      {children}
    </View>
  );
}

export function TableHeader({
  text,
  ...rest
}: { text?: string } & ComponentProps<typeof TableCell>) {
  const { Color } = useTheme();
  return (
    <TableCell
      {...rest}
      style={{
        alignSelf: 'flex-end',
        borderBottomWidth: 1,
        borderBottomColor: Color.styleGuide.Gray6,
        paddingBottom: 10,
        marginBottom: 15,
      }}
    >
      {text ? (
        <Text text={text} weight="semibold" color={Color.styleGuide.Gray4} size={13} />
      ) : null}
    </TableCell>
  );
}

export const ListItemStyle: StyleProp<ViewStyle> = {
  backgroundColor: 'white',
  borderRadius: 8,
  display: 'flex',
  flexDirection: 'row',
  padding: 8,
};

export const ListItemTouchable = (props: {
  to: string;
  params: object;
  action: StackActionType;
  children: ReactNode;
  testID?: string;
  selected?: boolean;
}) => {
  const navigation = useNavigation();
  return Platform.OS === 'web' ? (
    <LinkButton
      testID={props.testID}
      style={ListItemStyle}
      {...props}
      // @ts-expect-error
      dataSet={{ tablerow: '' }}
    />
  ) : (
    <TouchableOpacity
      testID={props.testID}
      style={{
        backgroundColor: 'white',
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'row',
        padding: 8,
      }}
      onPress={() => navigation.dispatch(props.action)}
    >
      {props.children}
    </TouchableOpacity>
  );
};

export function StaffTable<T>({
  columns,
  loading,
  renderItem,
  navigationExtractor,
  renderEmpty,
  ListEmptyComponent,
  showNavigationChevron = true,
  ...rest
}: {
  renderEmpty?: () => ReactNode;
  navigationExtractor?: (
    item: T,
    index: number,
  ) => { to: string; params: object; action: StackActionType; testID?: string };
  columns: (
    | string
    | {
        label: string;
        grow: number;
        initialWidth?: ComponentProps<typeof TableHeader>['initialWidth'];
      }
  )[];
  loading: boolean;
  showNavigationChevron?: boolean;
} & FlatListProps<T>) {
  const { Color } = useTheme();

  return (
    <>
      <View row style={{ padding: 0 }}>
        {(columns ?? []).map((c) => {
          const label = typeof c === 'string' ? c : c.label;
          const grow = typeof c === 'string' ? 1 : c.grow;
          const initialWidth = typeof c === 'string' ? undefined : c.initialWidth;
          return <TableHeader text={label} key={label} grow={grow} initialWidth={initialWidth} />;
        })}
        {navigationExtractor ? <TableHeader grow={0} initialWidth="auto" /> : null}
      </View>

      <FlatList
        ListEmptyComponent={() => {
          return loading ? (
            <View style={{ padding: 20 }}>
              <ActivityIndicator size="large" />
            </View>
          ) : renderEmpty ? (
            <View
              style={{
                backgroundColor: 'white',
                borderRadius: 8,
                display: 'flex',
                flexDirection: 'row',
                padding: 8,
              }}
            >
              {renderEmpty()}
            </View>
          ) : null;
        }}
        style={{
          backgroundColor: Color.grayBackground,
        }}
        ItemSeparatorComponent={() => <View style={{ height: 8 }} />}
        renderItem={(info) => {
          return navigationExtractor ? (
            <ListItemTouchable {...navigationExtractor?.(info.item, info.index)}>
              {renderItem?.(info)}
              {showNavigationChevron ? (
                <TableCell grow={0} initialWidth="auto">
                  <Icon name="caret-right" color={Color.styleGuide.BrandCarminePink} />
                </TableCell>
              ) : null}
            </ListItemTouchable>
          ) : (
            <>{renderItem?.(info)}</>
          );
        }}
        {...rest}
      />
    </>
  );
}
