import { ProductVariant } from '@oui/lib/src/types/graphql.generated';

export type ProductIntervention = 'AVIVA' | 'STATIC';
export type ProductDemographic = 'ADULT' | 'ADOLESCENT' | 'INPATIENT' | 'STATIC' | 'MILITARY';

export const INTERVENTION_LABELS: Record<ProductIntervention, string> = {
  AVIVA: 'Suicide Prevention (CBT app)',
  STATIC: 'Suicide Prevention (Sham app)',
};
export const DEMOGRAPHIC_LABELS: Record<ProductDemographic, string> = {
  ADULT: 'Adult',
  ADOLESCENT: 'Adolescent',
  INPATIENT: 'Inpatient',
  MILITARY: 'Military',
  STATIC: 'Placebo',
};

export const listDemographicsForProductVariants = (
  productVariants: ReadonlyArray<ProductVariant>,
) => {
  return [
    ...new Set(
      productVariants.map((i) => {
        return i.split('_')[1];
      }),
    ),
  ] as ProductDemographic[];
};

export const listPossibleInterventions = (productVariants: ReadonlyArray<ProductVariant>) => {
  return [
    ...new Set(
      productVariants.map((i) => {
        if (i === ProductVariant.AVIVA_STATIC) return 'STATIC';
        return i.split('_')[0];
      }),
    ),
  ] as ProductIntervention[];
};
