import { useRoute } from '@react-navigation/native';
import { useEffect } from 'react';
import { ScrollView } from 'react-native';

import { ChecklistWithPersistence } from '@src/components/Checklist';
import { useSetMyStoryMyPlanComplete } from '@src/hooks/useComposition';
import { usePatient } from '@src/hooks/usePatient';
import { ClinicianMyStoryMyPlanStackScreenProps, ContentType } from '@src/types';

export function ClinicianMyStoryMyPlanComplete() {
  const [setMyStoryMyPlanComplete] = useSetMyStoryMyPlanComplete();
  const route =
    useRoute<ClinicianMyStoryMyPlanStackScreenProps<'MyStoryMyPlanComplete'>['route']>();
  const patientID = route.params?.patientID;
  const { loading, data } = usePatient(patientID);

  useEffect(() => {
    if (!loading) {
      const progress = data?.patientByPatientID?.progress.find(
        (p) => p.content === ContentType.MYPLAN,
      );
      if (!progress?.completed) {
        setMyStoryMyPlanComplete({ variables: { patientID } });
      }
    }
  }, [loading, data, setMyStoryMyPlanComplete, patientID]);

  return (
    <ScrollView>
      <ChecklistWithPersistence
        compositionTitle="MyStoryMyPlanCompleteChecklist"
        title="Complete MyStory & MyPlan"
        steps={[
          {
            ID: 'open-questions',
            title: 'Any remaining questions or concerns',
            items: [],
          },
        ]}
      />
    </ScrollView>
  );
}
