import { ApolloError } from '@apollo/client';
import { useState } from 'react';

import { CheckboxListInput } from '@oui/app-core/src/components/CheckboxListInput';
import { OuiUserRoleType } from '@oui/lib/src/types/graphql.generated';
import { DeepWriteable, GQLUUID } from '@oui/lib/src/types/scalars';

import { ConfirmationModal } from '@src/components/ConfirmationModal';
import { ErrorPresenter } from '@src/components/ErrorPresenter';
import { FormModal } from '@src/components/FormModal';
import { OverflowMenu, OverflowMenuOption } from '@src/components/OverflowMenu';
import { PickerInput } from '@src/components/PickerInput';
import { Text } from '@src/components/Text';
import { EmailInput, TextInput } from '@src/components/TextInput';
import { View } from '@src/components/View';
import { useForm } from '@src/hooks/useForm';
import { formatTemplate } from '@src/lib/i18n';
import {
  AdminOrganizationQuery,
  UpdateOrganizationMemberMutationVariables,
  useAdminOrganizationQuery,
  useReplacePrimaryPractitionerMutation,
  useUpdateOrganizationMemberMutation,
} from '@src/screens/Organization.graphql.generated';
import Sentry from '@src/sentry';
import { useTheme } from '@src/styles';
import { namedAvivaOperations } from '@src/types/namedOperations.generated';

type OrgMember = NonNullable<AdminOrganizationQuery['organizationByID']>['members'][number];

const UpdateOrganizationMemberModal = ({
  organizationID,
  member,
  onRequestClose,
}: {
  organizationID: GQLUUID;
  member: OrgMember;
  onRequestClose: () => void;
}) => {
  const { bind, data, validate } = useForm<
    DeepWriteable<UpdateOrganizationMemberMutationVariables['update']>
  >(
    {
      person: {
        email: member.person.email,
        givenName: member.person.givenName,
        familyName: member.person.familyName,
      },
      roles: [...member.roles],
    },
    {
      merger: (originalValue: unknown, formValue: unknown) => {
        if (Array.isArray(originalValue)) {
          return formValue;
        }
        return undefined;
      },
    },
  );

  const [updateMember, { error }] = useUpdateOrganizationMemberMutation();

  const onUpdate = async () => {
    if (validate()) {
      await updateMember({
        variables: { organizationID, userID: member.userID, update: data },
        refetchQueries: [namedAvivaOperations.Query.AdminOrganization],
      });
      onRequestClose();
    }
  };

  return (
    <FormModal
      minWidth={600}
      visible
      onCancel={onRequestClose}
      onConfirm={onUpdate}
      confirmTestID="UpdateOrganizationMemberModal_confirmButton"
      title="Edit organization member"
    >
      <View spacing={25}>
        {error ? <ErrorPresenter error={error} /> : null}
        <View row childFlex={1} spacing={20}>
          <TextInput
            {...bind(['person', 'givenName'], {
              label: 'First name*',
              validator: { type: 'present' },
            })}
            placeholder="Christina"
          />
          <TextInput
            {...bind(['person', 'familyName'], {
              label: 'Last name*',
              validator: { type: 'present' },
            })}
            placeholder="Smith"
          />
        </View>
        <EmailInput
          {...bind(['person', 'email'], { label: 'Email*', validator: { type: 'present' } })}
          placeholder="email@domain.com"
        />
        <CheckboxListInput
          {...bind('roles', {
            label: 'Role*',
            validator: (val) => {
              if (val.length === 0) {
                return 'Must select at least one role';
              }
              return undefined;
            },
          })}
          labelSize={16}
          hideHint
          items={{
            [OuiUserRoleType.REGISTRAR]: 'Admin',
            [OuiUserRoleType.PRACTITIONER]: 'Practitioner',
          }}
        />
      </View>
    </FormModal>
  );
};

const ReplacePrimaryPractitionerModal = ({
  organizationID,
  numPatients,
  member,
  onRequestClose,
  onRemoveFromOrganization,
}: {
  organizationID: GQLUUID;
  numPatients: number;
  member: OrgMember;
  onRequestClose: () => void;
  onRemoveFromOrganization: () => Promise<void>;
}) => {
  const { data } = useAdminOrganizationQuery({
    variables: { organizationID },
    fetchPolicy: 'cache-first',
  });
  const [replacePrimaryPractitioner] = useReplacePrimaryPractitionerMutation();
  const form = useForm<{ primaryPractitionerID: GQLUUID }>({});

  const practitionerMembers =
    data?.organizationByID?.members.filter(
      (m) =>
        m.userID !== member.userID &&
        m.userRoles.find((r) => r.role === OuiUserRoleType.PRACTITIONER),
    ) ?? [];

  async function setPractitionerAndDelete() {
    const currentPrimaryPractitionerID = member.userRoles.find(
      (uR) => uR.role === OuiUserRoleType.PRACTITIONER,
    )?.ID;
    if (!currentPrimaryPractitionerID) {
      Sentry.captureException('cannot find matching currentPrimaryPractitionerID', {
        extra: { userID: member.userID, organizationID },
      });
      return;
    }

    await replacePrimaryPractitioner({
      variables: {
        currentPrimaryPractitionerID,
        newPrimaryPractitionerID: form.data.primaryPractitionerID,
      },
    });
    await onRemoveFromOrganization();
  }

  const memberName = member.person.givenName + ' ' + member.person.familyName;
  const patientsStr = formatTemplate('{numPatients, plural, one{1 patient} other{# patients}}', {
    numPatients,
  });

  return (
    <FormModal
      title={
        practitionerMembers.length > 0
          ? 'Set another primary clinician before deleting'
          : 'Sorry, cannot delete member'
      }
      minWidth={600}
      visible
      onCancel={practitionerMembers.length > 0 ? onRequestClose : undefined}
      onConfirm={practitionerMembers.length > 0 ? setPractitionerAndDelete : onRequestClose}
      confirmTestID="ReplacePrimaryPractitionerModal_confirmButton"
      confirmText={practitionerMembers.length > 0 ? 'Set and delete' : 'Close'}
    >
      <View spacing={25}>
        <Text text="">
          <Text weight="semibold" text={memberName} /> is a practitioner set as a primary clinician
          for {patientsStr}.{'\n\n'}You must set another practitioner available to this organization
          as the primary clinician for the {patientsStr} before you are able to delete {memberName}.
        </Text>
        {practitionerMembers.length > 0 ? (
          <PickerInput
            {...form.bind('primaryPractitionerID', {
              label: 'Set another primary clinician*',
              validator: { type: 'present' },
            })}
            placeholder="Select another practitioner"
            items={practitionerMembers.map((member) => ({
              label: [member.person.givenName, member.person.familyName].join(' '),
              value: member.userRoles.find((r) => r.role === OuiUserRoleType.PRACTITIONER)!.ID,
            }))}
          />
        ) : (
          <Text text={`\nCurrently, there are no other practitioners available`} />
        )}
      </View>
    </FormModal>
  );
};

export function OrganizationMemberActionPopupMenu({
  organizationName,
  organizationID,
  member,
  isParent,
}: {
  organizationName: string;
  organizationID: GQLUUID;
  member: OrgMember;
  isParent: boolean;
}) {
  const memberName = member.person.givenName + ' ' + member.person.familyName;
  const { Color } = useTheme();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [updateMember] = useUpdateOrganizationMemberMutation();
  const [numPatientsToReplacePrimaryPractitioner, setNumPatientsToReplacePrimaryPractitioner] =
    useState(0);

  const onRemoveFromOrganization = async () => {
    try {
      await updateMember({
        variables: { organizationID, userID: member.userID, update: { roles: [] } },
        refetchQueries: [namedAvivaOperations.Query.AdminOrganization],
      });
    } catch (e) {
      if (e instanceof ApolloError) {
        const primaryPractitionerError = e.graphQLErrors.find(
          (e) => typeof e.extensions?.numPatients === 'number',
        );
        if (primaryPractitionerError) {
          setNumPatientsToReplacePrimaryPractitioner(
            primaryPractitionerError.extensions?.numPatients as number,
          );
        }
      }
    }
  };

  return (
    <>
      <OverflowMenu
        triggerTestID="CopingCards_moreButton"
        triggerAccessibilityLabel="Member actions menu"
        triggerColor={Color.accent}
      >
        <OverflowMenuOption
          text="Edit"
          icon="edit"
          testID="CopingCards_editButton"
          onPress={() => {
            setShowEditModal(true);
          }}
        />
        <OverflowMenuOption
          icon="bin"
          text="Delete"
          testID="CopingCards_deleteButton"
          onPress={() => setShowDeleteModal(true)}
        />
      </OverflowMenu>
      {showEditModal ? (
        <UpdateOrganizationMemberModal
          organizationID={organizationID}
          member={member}
          onRequestClose={() => setShowEditModal(false)}
        />
      ) : null}
      {showDeleteModal ? (
        <ConfirmationModal
          confirmTestID="OrganizationMember_confirmDeleteButton"
          confirmText="Delete"
          showCloseIcon
          onCancel={() => setShowDeleteModal(false)}
          onConfirm={async () => {
            await onRemoveFromOrganization();
            setShowDeleteModal(false);
          }}
          title="Confirm Delete"
          description={
            isParent ? (
              <Text text="">
                Please confirm you{"'"}d like to delete <Text text={memberName} weight="semibold" />{' '}
                from <Text text={organizationName} weight="semibold" />.{'\n\n'}This member belongs
                to a parent organization. Deleting this member will remove their access and
                privileges from this org and all sub-orgs.
              </Text>
            ) : (
              <Text text="">
                Please confirm you{"'"}d like to delete <Text text={memberName} weight="semibold" />{' '}
                from <Text text={organizationName} weight="semibold" />.{'\n\n'}Deleting this member
                will only remove them from this sub-organization. It will not delete them from any
                other parent or sub-org.
              </Text>
            )
          }
          visible
          textStyle={{
            textAlign: 'left',
          }}
        />
      ) : null}
      {numPatientsToReplacePrimaryPractitioner ? (
        <ReplacePrimaryPractitionerModal
          organizationID={organizationID}
          numPatients={numPatientsToReplacePrimaryPractitioner}
          onRequestClose={() => setNumPatientsToReplacePrimaryPractitioner(0)}
          onRemoveFromOrganization={onRemoveFromOrganization}
          member={member}
        />
      ) : null}
    </>
  );
}
