import { ScrollView } from 'react-native';

import { ChecklistWithPersistence } from '@src/components/Checklist';

export function ClinicianMyStoryMyPlanOverview() {
  return (
    <ScrollView>
      <ChecklistWithPersistence
        compositionTitle="MyStoryMyPlanOverviewChecklist"
        title="Onboarding Overview"
        steps={[
          {
            ID: 'three-parts',
            title: 'Onboarding has 3 parts',
            items: [],
          },
          {
            ID: 'mystory',
            title: 'MyStory - your most recent crisis',
            items: [],
          },
          {
            ID: 'myriskcurve',
            title: 'Suicide Risk Curve - framework to think about suicide risk',
            items: [],
          },
          {
            ID: 'myplan',
            title: 'MyPlan - use MyStory and risk curve to develop a plan',
            items: [],
          },
        ]}
      />
    </ScrollView>
  );
}
