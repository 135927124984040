import { GQLUUID } from '@oui/lib/src/types/scalars';

import { usePatientDevicesQuery } from '@src/components/PatientSectionAdmin.graphql.generated';
import { ListItemStyle, StaffTable, TableCell } from '@src/components/StaffTable';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';

export function PatientSectionDevices({ patientID }: { patientID: GQLUUID }) {
  const { data, loading } = usePatientDevicesQuery({
    variables: { patientID },
  });
  const devices = data?.patientByPatientID?.devices;

  return (
    <View>
      <StaffTable
        keyExtractor={(item) => item.installationID}
        loading={loading}
        columns={['Installation ID', 'Platform', 'Active?']}
        data={devices}
        renderItem={({ item }) => {
          return (
            <View style={[ListItemStyle]}>
              <TableCell>
                <Text text={item.installationID} />
              </TableCell>
              <TableCell>
                <Text text={item.platform} />
              </TableCell>
              <TableCell>
                <Text text={item.pushActive?.toString() ?? 'false'} />
              </TableCell>
            </View>
          );
        }}
      />
    </View>
  );
}
