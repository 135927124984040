// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddPatientSupporterMutationVariables = Types.Exact<{
  organizationID: Types.Scalars['UUID']['input'];
  patientID: Types.Scalars['UUID']['input'];
  supporter: Types.PatientSupporterInput;
}>;


export type AddPatientSupporterMutation = { readonly addPatientSupporter: { readonly supporterID: CustomScalars.GQLUUID } };

export type IssueTokenLinkForSupporterMutationVariables = Types.Exact<{
  supporterID: Types.Scalars['UUID']['input'];
}>;


export type IssueTokenLinkForSupporterMutation = { readonly issueTokenLinkForSupporter: { readonly url: string, readonly value: string } };


export const AddPatientSupporterDocument = gql`
    mutation AddPatientSupporter($organizationID: UUID!, $patientID: UUID!, $supporter: PatientSupporterInput!) {
  addPatientSupporter(
    organizationID: $organizationID
    patientID: $patientID
    supporter: $supporter
  ) {
    supporterID
  }
}
    `;

/**
 * __useAddPatientSupporterMutation__
 *
 * To run a mutation, you first call `useAddPatientSupporterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPatientSupporterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPatientSupporterMutation, { data, loading, error }] = useAddPatientSupporterMutation({
 *   variables: {
 *      organizationID: // value for 'organizationID'
 *      patientID: // value for 'patientID'
 *      supporter: // value for 'supporter'
 *   },
 * });
 */
export function useAddPatientSupporterMutation(baseOptions?: Apollo.MutationHookOptions<AddPatientSupporterMutation, AddPatientSupporterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPatientSupporterMutation, AddPatientSupporterMutationVariables>(AddPatientSupporterDocument, options);
      }
export type AddPatientSupporterMutationHookResult = ReturnType<typeof useAddPatientSupporterMutation>;

export function performAddPatientSupporter(client: Apollo.ApolloClient<unknown>, variables: AddPatientSupporterMutationVariables, options?: Omit<Apollo.MutationOptions<AddPatientSupporterMutation, AddPatientSupporterMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<AddPatientSupporterMutation, AddPatientSupporterMutationVariables>({
    mutation: AddPatientSupporterDocument,
    variables,
    ...options,
  });
}

export const IssueTokenLinkForSupporterDocument = gql`
    mutation IssueTokenLinkForSupporter($supporterID: UUID!) {
  issueTokenLinkForSupporter(supporterID: $supporterID) {
    url
    value
  }
}
    `;

/**
 * __useIssueTokenLinkForSupporterMutation__
 *
 * To run a mutation, you first call `useIssueTokenLinkForSupporterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueTokenLinkForSupporterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueTokenLinkForSupporterMutation, { data, loading, error }] = useIssueTokenLinkForSupporterMutation({
 *   variables: {
 *      supporterID: // value for 'supporterID'
 *   },
 * });
 */
export function useIssueTokenLinkForSupporterMutation(baseOptions?: Apollo.MutationHookOptions<IssueTokenLinkForSupporterMutation, IssueTokenLinkForSupporterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IssueTokenLinkForSupporterMutation, IssueTokenLinkForSupporterMutationVariables>(IssueTokenLinkForSupporterDocument, options);
      }
export type IssueTokenLinkForSupporterMutationHookResult = ReturnType<typeof useIssueTokenLinkForSupporterMutation>;

export function performIssueTokenLinkForSupporter(client: Apollo.ApolloClient<unknown>, variables: IssueTokenLinkForSupporterMutationVariables, options?: Omit<Apollo.MutationOptions<IssueTokenLinkForSupporterMutation, IssueTokenLinkForSupporterMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<IssueTokenLinkForSupporterMutation, IssueTokenLinkForSupporterMutationVariables>({
    mutation: IssueTokenLinkForSupporterDocument,
    variables,
    ...options,
  });
}
