import { GQLUUID } from '@oui/lib/src/types/scalars';

import { usePatientNotificationsQuery } from '@src/components/PatientSectionAdmin.graphql.generated';
import { ListItemStyle, StaffTable, TableCell } from '@src/components/StaffTable';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { useI18n } from '@src/lib/i18n';

export function PatientSectionNotifications({ patientID }: { patientID: GQLUUID }) {
  const { data, loading } = usePatientNotificationsQuery({
    variables: { patientID },
  });
  const notifications = data?.patientByPatientID?.notifications;
  const intl = useI18n();

  return (
    <View>
      <StaffTable
        keyExtractor={(item) => item.notificationID}
        loading={loading}
        columns={['Notification ID', 'Rule', 'Variation', 'Created']}
        data={notifications}
        renderItem={({ item }) => {
          return (
            <View style={[ListItemStyle]}>
              <TableCell>
                <Text text={item.notificationID} />
              </TableCell>
              <TableCell>
                <Text text={item.rule} />
              </TableCell>
              <TableCell>
                <Text text={item.variation.toString()} />
              </TableCell>
              <TableCell>
                <Text text={intl.formatDate(item.createdAt)} />
              </TableCell>
            </View>
          );
        }}
      />
    </View>
  );
}
