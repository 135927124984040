import { GQLUUID } from '@oui/lib/src/types/scalars';

import { usePatientCaringContactsQuery } from '@src/components/PatientSectionAdmin.graphql.generated';
import { ListItemStyle, StaffTable, TableCell } from '@src/components/StaffTable';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { useI18n } from '@src/lib/i18n';

export function PatientSectionCaringContacts({ patientID }: { patientID: GQLUUID }) {
  const { data, loading } = usePatientCaringContactsQuery({
    variables: { patientID },
  });
  const notifications = data?.patientByPatientID?.caringContacts;
  const intl = useI18n();

  return (
    <View>
      <StaffTable
        keyExtractor={(item) => item.caringContactID}
        loading={loading}
        columns={['Caring Contact ID', 'Medium', 'Template Key', '3rd Party Service ID', 'Created']}
        data={notifications}
        renderItem={({ item }) => {
          return (
            <View style={[ListItemStyle]}>
              <TableCell>
                <Text text={item.caringContactID} />
              </TableCell>
              <TableCell>
                <Text text={item.caringContactsMedium} />
              </TableCell>
              <TableCell>
                <Text text={item.templateKey} />
              </TableCell>
              <TableCell>
                <Text text={item.thirdPartyServiceID} />
              </TableCell>
              <TableCell>
                <Text text={intl.formatDate(item.createdAt)} />
              </TableCell>
            </View>
          );
        }}
      />
    </View>
  );
}
