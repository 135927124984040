import { DiaryTabs, DiaryTabsProps } from '@src/components/DiaryTabs';

export function PlatformTabs<T extends string>(props: DiaryTabsProps<T>) {
  return (
    <DiaryTabs
      {...props}
      style={[
        {
          backgroundColor: 'white',
          borderRadius: 10,
          paddingHorizontal: 38,
          borderBottomWidth: 0,
        },
        props.style,
      ]}
      itemStyle={{
        flex: undefined,
        marginRight: 50,
        height: 44,
        justifyContent: 'flex-end',
      }}
    />
  );
}
