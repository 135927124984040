import { useEffect, useRef } from 'react';

/*
 * Same as useEffect except it skips the effect callback on the initial render
 */
export const useSubsequentEffect: typeof useEffect = (effect, deps) => {
  const initializedRef = useRef(false);

  useEffect(() => {
    if (initializedRef.current) {
      effect();
    } else {
      initializedRef.current = true;
    }
    // eslint-disable-next-line
  }, deps);
};
