import { ComponentProps, ReactNode } from 'react';
import { SafeAreaView, View } from 'react-native';

import { Breadcrumbs } from '@src/components/Breadcrumbs';
import { ScrollView } from '@src/components/ScrollView';
import { useTheme } from '@src/styles';

export function PlatformScreen({
  children,
  disableScroll,
  testID,
  innerTestID,
  crumbs,
}: {
  children?: ReactNode;
  disableScroll?: boolean;
  testID?: string;
  innerTestID?: string;
  crumbs: ComponentProps<typeof Breadcrumbs>['crumbs'];
}) {
  const { Color } = useTheme();
  return (
    <SafeAreaView
      style={{ flex: 1, backgroundColor: Color.grayBackground }}
      testID={innerTestID ? testID : undefined}
    >
      <Breadcrumbs crumbs={crumbs} />
      {disableScroll ? (
        <View
          testID={innerTestID ?? testID}
          style={{
            flex: 1,
            paddingHorizontal: 20,
            paddingVertical: 16,
          }}
        >
          {children}
        </View>
      ) : (
        <ScrollView
          testID={innerTestID ?? testID}
          style={{ alignSelf: 'stretch' }}
          contentContainerStyle={{
            flexGrow: 1,
            paddingHorizontal: 20,
            paddingVertical: 16,
          }}
        >
          {children}
        </ScrollView>
      )}
    </SafeAreaView>
  );
}
