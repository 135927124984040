import { createStackNavigator } from '@react-navigation/stack';
import { ReactNode, useEffect, useState } from 'react';
import { Clipboard, ScrollView } from 'react-native';

import { RNSlider } from '@oui/app-core/src/components/RNSlider';

import { Button } from '@src/components/Button';
import { LinkButton } from '@src/components/LinkButton';
import { PlatformScreen } from '@src/components/PlatformScreen';
import { Text } from '@src/components/Text';
import { TextInput } from '@src/components/TextInput';
import { View } from '@src/components/View';
import { DEFAULT_HEADER_MODE } from '@src/constants';
import { useIdTokenResult } from '@src/hooks/useIdTokenResult';
import { resumableUploadManager } from '@src/lib/resumableUploadManager';
import Emails from '@src/screens/Emails';
import { Color, card } from '@src/styles';
import { AdminStackParamList } from '@src/types';

function Link(props: { children: ReactNode; routeName: string }) {
  return <LinkButton to={props.routeName}>{props.children}</LinkButton>;
}

const API_SOURCE_URL = 'https://github.com/ouihealth/oui-aviva/commit/';
function Dashboard() {
  const [devVersion, setDevVersion] = useState('');
  const [prodVersion, setProdVersion] = useState('');

  useEffect(() => {
    if (window.location.host.startsWith('localhost:')) {
      setProdVersion('Unavailable on localhost');
    } else {
      fetch('https://core.oui.health/version')
        .then((r) => r.text())
        .then(setProdVersion);
    }
    fetch('https://core.oui.dev/version')
      .then((r) => r.text())
      .then(setDevVersion);
  }, []);

  return (
    <PlatformScreen crumbs={[{ label: 'Dashboard', to: 'admin' }]}>
      <View spacing={12} style={{ padding: 12 }}>
        <a href={API_SOURCE_URL + prodVersion} rel="noopener noreferrer" target="_blank">
          <Text text={`Production API: ${prodVersion}`} />
        </a>
        <a href={API_SOURCE_URL + devVersion} rel="noopener noreferrer" target="_blank">
          <Text text={`Development API: ${devVersion}`} />
        </a>
      </View>

      <View row spacing={12} style={{ padding: 12 }}>
        <View style={[card, { padding: 12 }]}>
          <Link routeName="admin/emails">Emails</Link>
        </View>
        <View style={[card, { padding: 12 }]}>
          <Link routeName="admin/firebase">Firebase Debug</Link>
        </View>
        <View style={[card, { padding: 12 }]}>
          <Link routeName="admin/upload">Test Resumable Upload</Link>
        </View>
      </View>
    </PlatformScreen>
  );
}

function TestResumableUpload() {
  const [sessionUri, setSessionUri] = useState<string>();
  const [fileToUpload, setFileToUpload] = useState<File>();
  const [progress, setProgress] = useState(0);
  async function upload() {
    const uri = URL.createObjectURL(fileToUpload!);
    const ID = await resumableUploadManager.uploadFile(uri, sessionUri as any);
    const remove = resumableUploadManager.addListener(ID, ({ percent }) => {
      setProgress(percent);
      if (percent === 100) {
        remove();
        setFileToUpload(undefined);
      }
    });
  }
  return (
    <ScrollView>
      <View style={{ userSelect: 'text', padding: 20 } as any} spacing={20}>
        <TextInput value={sessionUri ?? ''} onChangeValue={setSessionUri} label="Session URI" />
        <input
          type="file"
          accept="video/mp4"
          onChange={(e) => {
            // Using an input intead of expo-image-picker b/c they dont give us back the original blob.
            // Later can probably adapt most of the code from expo to handle more complex file choosing UI:
            // https://github.com/expo/expo/blob/master/packages/expo-image-picker/src/ExponentImagePicker.web.ts
            const file = e.target.files?.item(0);
            setFileToUpload(file ?? undefined);
            console.log(file);
          }}
        />
        <RNSlider
          animateTransitions={true}
          minimumValue={0}
          maximumValue={100}
          value={progress}
          onValueChange={() => {}}
          style={{ width: '100%' }}
          minimumTrackTintColor={Color.success}
          maximumTrackTintColor={Color.styleGuide.Gray6}
          trackStyle={{
            height: 6,
            borderRadius: 6,
          }}
          thumbStyle={{
            height: 6,
            width: 0,
          }}
          allowSliding={false}
        />
        <Button text="Upload" onPress={upload} />
      </View>
    </ScrollView>
  );
}

function Firebase() {
  const idTokenResult = useIdTokenResult();

  return (
    <ScrollView>
      <View style={{ userSelect: 'text', padding: 20 } as any} spacing={20}>
        <View row spacing={12}>
          <Button text="Copy token" onPress={() => Clipboard.setString(idTokenResult?.token)} />
          <Button
            text="Copy auth header"
            onPress={() =>
              Clipboard.setString(
                JSON.stringify({ Authorization: `Bearer ${idTokenResult?.token}` }),
              )
            }
          />
        </View>
        <Text text={JSON.stringify(idTokenResult, null, 2)} />
      </View>
    </ScrollView>
  );
}

const Stack = createStackNavigator<AdminStackParamList>();
export const Navigator = () => (
  <Stack.Navigator
    screenOptions={() => ({
      headerShown: false,
      cardStyle: { backgroundColor: Color.grayBackground },
      headerMode: DEFAULT_HEADER_MODE,
    })}
    initialRouteName="Dashboard"
  >
    <Stack.Screen name="Dashboard" component={Dashboard} options={{ headerLeft: undefined }} />
    <Stack.Screen name="Emails" component={Emails} />
    <Stack.Screen name="Firebase" component={Firebase} />
    <Stack.Screen name="TestResumableUpload" component={TestResumableUpload} />
  </Stack.Navigator>
);

export const Admin = Navigator;
