// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientQueryVariables = Types.Exact<{
  patientID: Types.Scalars['UUID']['input'];
}>;


export type PatientQuery = { readonly patientByPatientID?: { readonly __typename: 'PatientProfile', readonly lastLogin?: CustomScalars.GQLDateTime | null, readonly patient: { readonly __typename: 'Patient', readonly ID: CustomScalars.GQLUUID, readonly productVersion: Types.ProductVersion, readonly productVariant: Types.ProductVariant, readonly productStatic: boolean, readonly onboardingVariant: Types.OnboardingVariant, readonly caringContactsMedium: Types.CaringContactsMedium, readonly createdAt: CustomScalars.GQLDateTime, readonly person: { readonly email?: string | null, readonly givenName?: string | null, readonly familyName?: string | null, readonly race: Types.UserRace, readonly birthDate?: CustomScalars.GQLDate | null, readonly genderIdentity: Types.UserGenderIdentity, readonly sexualOrientation: Types.UserSexualOrientation, readonly militaryStatus: Types.UserMilitaryStatus, readonly address: ReadonlyArray<{ readonly use: Types.AddressUse, readonly line?: ReadonlyArray<string> | null, readonly city?: string | null, readonly state?: string | null, readonly postalCode?: string | null }>, readonly contactPoint: ReadonlyArray<{ readonly ID: CustomScalars.GQLUUID, readonly system?: string | null, readonly use?: string | null, readonly value?: string | null }> } }, readonly supporters: ReadonlyArray<{ readonly relation: Types.PatientSupporterRelation, readonly claimed?: boolean | null, readonly supporterID: CustomScalars.GQLUUID, readonly patientID: CustomScalars.GQLUUID, readonly supporter: { readonly createdAt: CustomScalars.GQLDateTime, readonly person: { readonly givenName?: string | null, readonly familyName?: string | null, readonly userID: CustomScalars.GQLUUID } } }>, readonly progress: ReadonlyArray<{ readonly completed: boolean, readonly completion: number, readonly content: Types.ContentType, readonly updatedAt: CustomScalars.GQLDateTime }>, readonly careTeam: { readonly organizations: ReadonlyArray<{ readonly isTrialOrganization: boolean }> } } | null };

export type IssueActivationTokenMutationVariables = Types.Exact<{
  patientID: Types.Scalars['UUID']['input'];
}>;


export type IssueActivationTokenMutation = { readonly issueToken: { readonly value: string, readonly expiresAt: CustomScalars.GQLDateTime } };

export type AddPatientMutationVariables = Types.Exact<{
  organizationID: Types.Scalars['UUID']['input'];
  patient: Types.PatientInput;
  primaryPractitionerID: Types.Scalars['UUID']['input'];
}>;


export type AddPatientMutation = { readonly addPatient: { readonly ID: CustomScalars.GQLUUID } };


export const PatientDocument = gql`
    query Patient($patientID: UUID!) {
  patientByPatientID(patientID: $patientID) {
    __typename
    patient {
      __typename
      ID
      productVersion
      productVariant
      productStatic
      onboardingVariant
      caringContactsMedium
      createdAt
      person {
        email
        givenName
        familyName
        race
        birthDate
        genderIdentity
        sexualOrientation
        militaryStatus
        address {
          use
          line
          city
          state
          postalCode
        }
        contactPoint {
          ID
          system
          use
          value
        }
      }
    }
    supporters {
      supporter {
        person {
          givenName
          familyName
          userID
        }
        createdAt
      }
      relation
      claimed
      supporterID
      patientID
    }
    progress {
      completed
      completion
      content
      updatedAt
    }
    lastLogin
    careTeam {
      organizations {
        isTrialOrganization
      }
    }
  }
}
    `;

/**
 * __usePatientQuery__
 *
 * To run a query within a React component, call `usePatientQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientQuery({
 *   variables: {
 *      patientID: // value for 'patientID'
 *   },
 * });
 */
export function usePatientQuery(baseOptions: Apollo.QueryHookOptions<PatientQuery, PatientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientQuery, PatientQueryVariables>(PatientDocument, options);
      }
export function usePatientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientQuery, PatientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientQuery, PatientQueryVariables>(PatientDocument, options);
        }
export type PatientQueryHookResult = ReturnType<typeof usePatientQuery>;
export type PatientLazyQueryHookResult = ReturnType<typeof usePatientLazyQuery>;

export function queryPatient(client: Apollo.ApolloClient<unknown>, variables: PatientQueryVariables, options?: Omit<Apollo.QueryOptions<PatientQuery, PatientQueryVariables>, "query" | 'variables'>) {
  return client.query<PatientQuery, PatientQueryVariables>({
    query: PatientDocument,
    variables,
    ...options,
  });
}

export const IssueActivationTokenDocument = gql`
    mutation IssueActivationToken($patientID: UUID!) {
  issueToken(patientID: $patientID) {
    value
    expiresAt
  }
}
    `;

/**
 * __useIssueActivationTokenMutation__
 *
 * To run a mutation, you first call `useIssueActivationTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueActivationTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueActivationTokenMutation, { data, loading, error }] = useIssueActivationTokenMutation({
 *   variables: {
 *      patientID: // value for 'patientID'
 *   },
 * });
 */
export function useIssueActivationTokenMutation(baseOptions?: Apollo.MutationHookOptions<IssueActivationTokenMutation, IssueActivationTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IssueActivationTokenMutation, IssueActivationTokenMutationVariables>(IssueActivationTokenDocument, options);
      }
export type IssueActivationTokenMutationHookResult = ReturnType<typeof useIssueActivationTokenMutation>;

export function performIssueActivationToken(client: Apollo.ApolloClient<unknown>, variables: IssueActivationTokenMutationVariables, options?: Omit<Apollo.MutationOptions<IssueActivationTokenMutation, IssueActivationTokenMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<IssueActivationTokenMutation, IssueActivationTokenMutationVariables>({
    mutation: IssueActivationTokenDocument,
    variables,
    ...options,
  });
}

export const AddPatientDocument = gql`
    mutation AddPatient($organizationID: UUID!, $patient: PatientInput!, $primaryPractitionerID: UUID!) {
  addPatient(
    organizationID: $organizationID
    patient: $patient
    primaryPractitionerID: $primaryPractitionerID
  ) {
    ID
  }
}
    `;

/**
 * __useAddPatientMutation__
 *
 * To run a mutation, you first call `useAddPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPatientMutation, { data, loading, error }] = useAddPatientMutation({
 *   variables: {
 *      organizationID: // value for 'organizationID'
 *      patient: // value for 'patient'
 *      primaryPractitionerID: // value for 'primaryPractitionerID'
 *   },
 * });
 */
export function useAddPatientMutation(baseOptions?: Apollo.MutationHookOptions<AddPatientMutation, AddPatientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPatientMutation, AddPatientMutationVariables>(AddPatientDocument, options);
      }
export type AddPatientMutationHookResult = ReturnType<typeof useAddPatientMutation>;

export function performAddPatient(client: Apollo.ApolloClient<unknown>, variables: AddPatientMutationVariables, options?: Omit<Apollo.MutationOptions<AddPatientMutation, AddPatientMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<AddPatientMutation, AddPatientMutationVariables>({
    mutation: AddPatientDocument,
    variables,
    ...options,
  });
}
