import { HeaderBackButton } from '@react-navigation/elements';
import { StackNavigationOptions } from '@react-navigation/stack';
import * as ScreenOrientation from 'expo-screen-orientation';
import { ComponentProps, Suspense, lazy } from 'react';
import { Platform, StatusBar } from 'react-native';

import { AppContainer } from '@oui/app-core/src/components/AppContainer';
import { getConfigString } from '@oui/app-core/src/lib/remoteConfig';
import Auth from '@oui/app-core/src/screens/Auth';
import { AuthParamList, DeeplinkConfigShape } from '@oui/app-core/src/types/navigation';

import FullLogo from '@src/assets/Full_logo.svg';
import { PlatformSidebar } from '@src/components/PlatformSidebar';
import { View } from '@src/components/View';
import { DEFAULT_HEADER_MODE, FLAGS, SESSION_TIMEOUT } from '@src/constants';
import { useCurrentUser } from '@src/hooks/useCurrentUser';
import { createApolloClient } from '@src/lib/apolloClient';
import { createSessionTimeoutStackNavigator } from '@src/lib/createSessionTimeoutNavigator';
import { useI18n } from '@src/lib/i18n';
import AccountSettings from '@src/screens/AccountSettings';
import { Admin } from '@src/screens/Admin';
import { Assessment } from '@src/screens/Assessment';
import { EditOrganization } from '@src/screens/EditOrganization';
import { MyStoryMyPlan as MyStoryMyPlanV2 } from '@src/screens/MyStoryMyPlanStack';
import { NewOrganization } from '@src/screens/NewOrganization';
import { NewPatientV2 } from '@src/screens/NewPatientV2';
import { Organization } from '@src/screens/Organization';
import { Organizations } from '@src/screens/Organizations';
import { PatientV2 } from '@src/screens/PatientV2';
import { PatientsV2 } from '@src/screens/PatientsV2';
import { TrialAssignments } from '@src/screens/TrialAssignments';
import { useTheme } from '@src/styles';
import {
  CMSStackParamList,
  ClinicianMyStoryMyPlanStackParamList,
  CliniciansStackParamList,
} from '@src/types';

if (Platform.OS !== 'web') {
  ScreenOrientation.unlockAsync();
} else if (navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations().then(async function (registrations) {
    for (let registration of registrations) {
      await registration.unregister();
    }
  });
  if ('caches' in window) {
    caches.keys().then(function (keyList) {
      return Promise.all(
        keyList.map(function (key) {
          return caches.delete(key);
        }),
      );
    });
  }
}

const CMSLazy = lazy(() => import('@src/screens/CMS'));

const CliniciansStack =
  createSessionTimeoutStackNavigator<CliniciansStackParamList>(SESSION_TIMEOUT);
const Clinicians = () => {
  const { scheme: theme, Color } = useTheme();
  const { data, isLoggedIn } = useCurrentUser();
  const claims = data?.currentUser?.attributes;
  const { $t } = useI18n();

  return (
    <View
      row
      style={{ height: '100%', alignItems: 'stretch', backgroundColor: Color.grayBackground }}
    >
      {isLoggedIn && Platform.OS === 'web' ? <PlatformSidebar /> : null}
      <CliniciansStack.Navigator
        screenOptions={(screenProps) => {
          const options: StackNavigationOptions = {
            headerMode: DEFAULT_HEADER_MODE,
            headerShown: Platform.OS !== 'web',
            title: '',
            headerTitleAlign: 'center',
            headerTintColor: 'white',
            headerStyle: { backgroundColor: Color.tertiary, height: 66 },
            headerLeft: (props) => (
              <HeaderBackButton
                {...props}
                onPress={() => {
                  screenProps.navigation.navigate('Patients');
                }}
              />
            ),
          };
          return options;
        }}
      >
        {isLoggedIn ? (
          <>
            {claims?.trialAuditor ? (
              <>
                <CliniciansStack.Screen
                  name="TrialAssignments"
                  component={TrialAssignments}
                  options={{ cardStyle: { backgroundColor: Color.grayBackground } }}
                />
              </>
            ) : null}
            <CliniciansStack.Screen
              name="Patients"
              component={PatientsV2}
              options={() => ({
                title: 'Patients',
                headerShown: false,
              })}
            />
            <CliniciansStack.Screen
              name="Patient"
              component={PatientV2}
              options={() => ({
                title: 'Patient',
                headerShown: false,
              })}
            />
            <CliniciansStack.Screen
              name="NewPatient"
              component={NewPatientV2}
              options={() => ({
                title: 'Add Patient',
                headerShown: false,
              })}
            />
            <CliniciansStack.Screen name="MyStoryMyPlan" component={MyStoryMyPlanV2} />
            <CliniciansStack.Screen name="Assessment" component={Assessment} />
            <CliniciansStack.Screen
              name="AccountSettings"
              component={AccountSettings}
              options={{
                headerShown: true,
                headerLeft: () => null,
                headerStyle: { backgroundColor: 'transparent' },
                headerTintColor: Color.text,
              }}
            />
            <CliniciansStack.Screen
              name="Admin"
              component={Admin}
              options={{ headerShown: false }}
            />
            <CliniciansStack.Screen
              name="NewOrganization"
              component={NewOrganization}
              options={{
                title: 'Add new organization',
                cardStyle: { backgroundColor: Color.grayBackground },
              }}
            />
            <CliniciansStack.Screen
              name="Organizations"
              component={Organizations}
              options={{ cardStyle: { backgroundColor: Color.grayBackground } }}
            />
            <CliniciansStack.Screen
              name="Organization"
              component={Organization}
              options={{ cardStyle: { backgroundColor: Color.grayBackground } }}
            />
            <CliniciansStack.Screen
              name="EditOrganization"
              component={EditOrganization}
              options={{ cardStyle: { backgroundColor: Color.grayBackground } }}
            />
            <CliniciansStack.Screen
              name="CMS"
              component={CMSLazy}
              options={{ headerShown: false }}
            />
          </>
        ) : (
          Auth({ $t, Screen: CliniciansStack.Screen, theme, Color })
        )}
      </CliniciansStack.Navigator>
    </View>
  );
};

const MyStoryMyPlanConfig: DeeplinkConfigShape<keyof ClinicianMyStoryMyPlanStackParamList> = {
  MyStoryMyPlanOverview: 'mystorymyplan/overview',
  MyStoryIntroduction: 'mystorymyplan/introduction',
  MyStoryTimeline: 'mystorymyplan/timeline',
  MyStoryTimelineReview: 'mystorymyplan/timeline-review',
  MyStoryTimelineFinal: 'mystorymyplan/timeline-final',
  MyStoryRiskCurveIntroduction: 'mystorymyplan/risk-curve-introduction',
  MyStoryRiskCurveReview: 'mystorymyplan/risk-curve-review',
  MyStoryMyPlanIntroduction: 'mystorymyplan/myplan-introduction',
  MyStoryMyPlanReview: 'mystorymyplan/myplan-review',
  MyStoryMyPlanComplete: 'mystorymyplan/complete',
};

const DEEPLINK_CONFIG: DeeplinkConfigShape<
  Exclude<keyof CliniciansStackParamList, keyof AuthParamList>
> = {
  AccountSettings: 'account',
  ClinicianLock: 'clinician-lock',
  NewPatient: 'patients/new',
  Patients: 'patients',
  Patient: 'patients/:patientID',
  Assessment: 'assessment',
  MyStoryMyPlan: { screens: MyStoryMyPlanConfig },
  Admin: {
    screens: {
      Dashboard: 'admin',
      Emails: 'admin/emails',
      Firebase: 'admin/firebase',
      TestResumableUpload: 'admin/upload',
      Styleguide: 'admin/styleguide',
    },
  },
  NewOrganization: 'organizations/new',
  Organizations: 'organizations',
  Organization: 'organizations/:organizationID',
  EditOrganization: 'organizations/:organizationID/edit',
  CMS: {
    screens: {
      CMSApps: 'cms/apps',
      CMSQuizSetCollections: 'cms/quiz-set-collections',
      CMSQuizSetCollection: 'cms/quiz-set-collections/:quizSetCollectionID',
      CMSNewApp: 'cms/apps/new',
      CMSEditApp: 'cms/app/:appID/edit',
      CMSNewVariant: 'cms/app/:appID/variants/new',
      CMSEditVariant: 'cms/app/:appID/variant/:variantID/edit',
      CMSEditVariantGroup: 'cms/app/:appID/variant/:variantID/groups/:groupID/edit',
      CMSSessions: 'cms/app/:appID/variant/:variantID',
      CMSSessionQuizSetCollections:
        'cms/app/:appID/variant/:variantID/session/:sessionID/quiz-set-collections',
      CMSEditSession: 'cms/app/:appID/variant/:variantID/session/:sessionID/edit',
      CMSNewSession: 'cms/app/:appID/variant/:variantID/sessions/new',
      CMSSessionContent: 'cms/app/:appID/variant/:variantID/session/:sessionID/content',
    } as DeeplinkConfigShape<keyof CMSStackParamList>,
  },
  TrialAssignments: 'trial-assignments',
};

const getMessages: ComponentProps<typeof AppContainer>['getMessages'] = ({ lang }) => {
  switch (lang) {
    case 'en': {
      return require('@src/messages/compiled/en.json');
    }
    default:
      return {};
  }
};

const apollo = createApolloClient(getConfigString('apiUrl'), {
  subscriptionUri: getConfigString('subscriptionUri'),
  connectToDevTools: true,
});
export default function StaffApp() {
  return (
    <Suspense fallback={null}>
      <AppContainer
        Logo={FullLogo}
        flags={FLAGS}
        apollo={apollo}
        deeplinkConfig={{ screens: DEEPLINK_CONFIG }}
        initialPath={({ ouiUser }) => {
          if (!ouiUser) return 'auth/login';
          return ouiUser.attributes.trialAuditor ? 'trial-assignments' : 'patients';
        }}
        app={() => {
          return (
            <>
              <StatusBar barStyle="light-content" />
              <Clinicians />
            </>
          );
        }}
        getMessages={getMessages}
      />
    </Suspense>
  );
}
