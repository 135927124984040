import { Link, NavigationContext, NavigationRouteContext, Route } from '@react-navigation/native';
import {
  ComponentProps,
  ReactElement,
  MouseEvent as ReactMouseEvent,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { GestureResponderEvent } from 'react-native';
import { useDebounce } from 'use-debounce/lib';

import { OuiUserRoleType } from '@oui/lib/src/types/graphql.generated';

import OuihealthLogo from '@src/assets/ouihealthLogo.svg';
import { ContactImage } from '@src/components/Contact';
import { Icon } from '@src/components/Icon';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { Environment, IS_PRODUCTION, environment } from '@src/constants';
import { useAppContext } from '@src/hooks/useAppContext';
import { useCurrentUser } from '@src/hooks/useCurrentUser';
import { useDailyState } from '@src/hooks/useDailyState';
import { useWindowDimensions } from '@src/hooks/useWindowDimensions';
import { useTheme } from '@src/styles';

const ITEM_HORIZONTAL_PADDING = 24;

function SidebarSection(props: { title: string; children: ReactNode }) {
  const { Color } = useTheme();
  return (
    <View style={{ alignItems: 'stretch' }}>
      <Text
        text={props.title}
        weight="bold"
        size={15}
        color={Color.styleGuide.Gray6}
        style={{ marginBottom: 16 }}
      />
      <View spacing={5}>{props.children}</View>
    </View>
  );
}

function SidebarItem(props: {
  to: string;
  onPress?: () => void;
  icon: ComponentProps<typeof Icon>['name'] | ReactElement;
  text: string;
  testID: string;
}) {
  const [_, setRando] = useState(0);
  const pathname = global.location.pathname;

  useEffect(() => {
    // component is rendered just before pathname is updated, so this hacky workaround let's us
    // make sure we're rendering based on the latest pathname
    const timeout = setTimeout(() => {
      if (pathname !== global.location.pathname) {
        setRando(Math.random());
      }
    }, 20);
    return () => clearTimeout(timeout);
  });

  return (
    <Link
      to={props.to}
      style={{ display: 'flex' }}
      testID={props.testID}
      onPress={
        props.onPress
          ? (e: ReactMouseEvent<HTMLAnchorElement, MouseEvent> | GestureResponderEvent) => {
              e.preventDefault?.();
              props.onPress?.();
            }
          : undefined
      }
    >
      <View
        row
        style={[
          { flex: 1, marginHorizontal: -ITEM_HORIZONTAL_PADDING },
          { paddingVertical: 12, paddingHorizontal: ITEM_HORIZONTAL_PADDING },
          pathname.slice(1).startsWith(props.to.split('/')[1])
            ? { backgroundColor: 'rgba(255,255,255,0.15)' }
            : null,
        ]}
        spacing={8}
      >
        {typeof props.icon === 'string' ||
        (props.icon && typeof props.icon === 'object' && 'src' in props.icon) ? (
          <Icon name={props.icon} color="white" size={20} />
        ) : (
          props.icon
        )}
        <Text text={props.text} color="white" weight="semibold" size={17} />
      </View>
    </Link>
  );
}

export function PlatformSidebar() {
  const [expandedState, setExpandedState] = useDailyState<'expanded' | 'collapsed' | 'auto'>(
    'drawerState',
    'auto',
  );
  const { width: _width } = useWindowDimensions();
  const { navigationContainer } = useAppContext();
  const [activeRoute, setActiveRoute] = useState<Route<string> | undefined>(undefined);
  const { Color } = useTheme();
  const [width] = useDebounce(_width, 100);
  const { data, isAdmin } = useCurrentUser();
  const claims = data?.currentUser?.attributes;
  const name = data?.currentUser
    ? `${data.currentUser.user?.person.givenName} ${data.currentUser.user?.person.familyName}`
    : '';

  useEffect(() => {
    if (width < 768) {
      setExpandedState((state) => (state === 'expanded' ? 'auto' : state));
    }
  }, [width, setExpandedState]);

  useEffect(() => {
    if (navigationContainer) {
      setActiveRoute(navigationContainer.getCurrentRoute());
      return navigationContainer.addListener('state', (e) => {
        setActiveRoute(navigationContainer.getCurrentRoute());
      });
    }
    return;
  }, [navigationContainer]);

  const shouldSeeOrganizationsLink =
    data?.currentUser?.roles.find((role) =>
      /* OUI_ADMIN is deprecated in favor of attributes.admin */
      ([OuiUserRoleType.OUI_ADMIN, OuiUserRoleType.REGISTRAR] as OuiUserRoleType[]).includes(
        role.role,
      ),
    ) || data?.currentUser?.attributes.admin;

  const inner = (
    <View
      spacing={30}
      style={{
        height: '100%',
        paddingVertical: 24,
        paddingHorizontal: ITEM_HORIZONTAL_PADDING,
        width: 250,
        backgroundColor: Color.tertiary,
        borderTopEndRadius: 30,
      }}
    >
      <View row style={{ justifyContent: 'space-between' }}>
        <Link to="/patients">
          <View spacing={6} row>
            <OuihealthLogo accessibilityLabel={undefined} />
            <Text
              text="Oui Health"
              style={{ fontFamily: 'serif', fontWeight: '600' }}
              color="white"
              size={23}
            />
          </View>
        </Link>
        <Icon
          style={{ opacity: 0.6 }}
          color="white"
          accessibilityLabel="Expand sidebar"
          name="arrow-left"
          onPress={() => {
            setExpandedState('collapsed');
          }}
        />
      </View>
      <View spacing={40}>
        <SidebarSection title="Manage">
          <SidebarItem to="/patients" testID="Patients_link" icon="people" text="Patients" />
          {shouldSeeOrganizationsLink ? (
            <SidebarItem
              to="/organizations"
              testID="Organizations_link"
              icon="parent-organization"
              text="Organizations"
            />
          ) : null}
          {claims?.trialAuditor ? (
            <SidebarItem
              to="/trial-assignments"
              testID="TrialAssignments_link"
              icon="sessions"
              text="Trial assignments"
            />
          ) : null}
        </SidebarSection>
        <SidebarSection title={isAdmin ? 'Admin' : 'Other'}>
          {IS_PRODUCTION ? null : (
            <SidebarItem to="/cms/apps" icon="text-page" text="Content" testID="Content_link" />
          )}
          {isAdmin || environment !== Environment.PRODUCTION ? (
            <SidebarItem
              to="#"
              onPress={() => {
                (global as any).showDevMenu();
              }}
              icon="crisis-peak"
              text="Open Debug Menu"
              testID="DebugMenu_link"
            />
          ) : (
            <SidebarItem
              to="#"
              onPress={() => {
                (global as any).showReportErrorMenu();
              }}
              icon="crisis-peak"
              text="Submit bug report"
              testID="BugReport_link"
            />
          )}
          {isAdmin || environment === Environment.DEVELOPMENT ? (
            <SidebarItem to="/admin" icon="settings" text="Admin dashboard" testID="Admin_link" />
          ) : null}
        </SidebarSection>
        <SidebarSection title="Account">
          <SidebarItem
            to="/account"
            icon={<ContactImage nameStr={name} image={undefined} imageSize={25} inverted />}
            text="Account"
            testID="Account_link"
          />
        </SidebarSection>
      </View>
    </View>
  );

  return activeRoute ? (
    <NavigationContext.Provider value={navigationContainer as any}>
      <NavigationRouteContext.Provider value={activeRoute}>
        {width < 768 || expandedState === 'collapsed' ? (
          <>
            <View
              style={{
                paddingVertical: 24,
                paddingHorizontal: 12,
                alignItems: 'center',
                backgroundColor: Color.tertiary,
              }}
              spacing={30}
            >
              <Link to="/patients">
                <OuihealthLogo accessibilityLabel={undefined} />
              </Link>
              <Icon
                size={24}
                color="white"
                accessibilityLabel="Expand sidebar"
                name="arrow-right"
                onPress={() => {
                  setExpandedState('expanded');
                }}
              />
            </View>
            {expandedState === 'expanded' ? (
              <View style={{ position: 'absolute', zIndex: 1, top: 0, bottom: 0 }}>{inner}</View>
            ) : null}
          </>
        ) : (
          inner
        )}
      </NavigationRouteContext.Provider>
    </NavigationContext.Provider>
  ) : null;
}
