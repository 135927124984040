// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientProfileAdminFragment = { readonly __typename: 'PatientProfile', readonly patient: { readonly __typename: 'Patient', readonly ID: CustomScalars.GQLUUID } };

export type PatientDevicesQueryVariables = Types.Exact<{
  patientID: Types.Scalars['UUID']['input'];
}>;


export type PatientDevicesQuery = { readonly patientByPatientID?: { readonly __typename: 'PatientProfile', readonly devices: ReadonlyArray<{ readonly __typename: 'Device', readonly installationID: CustomScalars.GQLUUID, readonly platform: string, readonly pushActive?: boolean | null }>, readonly patient: { readonly __typename: 'Patient', readonly ID: CustomScalars.GQLUUID } } | null };

export type PatientNotificationsQueryVariables = Types.Exact<{
  patientID: Types.Scalars['UUID']['input'];
}>;


export type PatientNotificationsQuery = { readonly patientByPatientID?: { readonly __typename: 'PatientProfile', readonly notifications: ReadonlyArray<{ readonly __typename: 'PatientNotification', readonly notificationID: CustomScalars.GQLUUID, readonly rule: string, readonly variation: number, readonly createdAt: CustomScalars.GQLDateTime }>, readonly patient: { readonly __typename: 'Patient', readonly ID: CustomScalars.GQLUUID } } | null };

export type PatientEmailsQueryVariables = Types.Exact<{
  patientID: Types.Scalars['UUID']['input'];
}>;


export type PatientEmailsQuery = { readonly patientByPatientID?: { readonly __typename: 'PatientProfile', readonly emails: ReadonlyArray<{ readonly __typename: 'UserEmail', readonly userEmailID: CustomScalars.GQLUUID, readonly thirdPartyServiceID: string, readonly description: string, readonly createdAt: CustomScalars.GQLDateTime }>, readonly patient: { readonly __typename: 'Patient', readonly ID: CustomScalars.GQLUUID } } | null };

export type PatientCaringContactsQueryVariables = Types.Exact<{
  patientID: Types.Scalars['UUID']['input'];
}>;


export type PatientCaringContactsQuery = { readonly patientByPatientID?: { readonly __typename: 'PatientProfile', readonly caringContacts: ReadonlyArray<{ readonly __typename: 'PatientCaringContact', readonly caringContactID: CustomScalars.GQLUUID, readonly caringContactsMedium: string, readonly templateKey: string, readonly thirdPartyServiceID: string, readonly createdAt: CustomScalars.GQLDateTime }>, readonly patient: { readonly __typename: 'Patient', readonly ID: CustomScalars.GQLUUID } } | null };

export const PatientProfileAdminFragmentDoc = gql`
    fragment PatientProfileAdmin on PatientProfile {
  __typename
  patient {
    __typename
    ID
  }
}
    `;
export const PatientDevicesDocument = gql`
    query PatientDevices($patientID: UUID!) {
  patientByPatientID(patientID: $patientID) {
    ...PatientProfileAdmin
    devices {
      __typename
      installationID
      platform
      pushActive
    }
  }
}
    ${PatientProfileAdminFragmentDoc}`;

/**
 * __usePatientDevicesQuery__
 *
 * To run a query within a React component, call `usePatientDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientDevicesQuery({
 *   variables: {
 *      patientID: // value for 'patientID'
 *   },
 * });
 */
export function usePatientDevicesQuery(baseOptions: Apollo.QueryHookOptions<PatientDevicesQuery, PatientDevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientDevicesQuery, PatientDevicesQueryVariables>(PatientDevicesDocument, options);
      }
export function usePatientDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientDevicesQuery, PatientDevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientDevicesQuery, PatientDevicesQueryVariables>(PatientDevicesDocument, options);
        }
export type PatientDevicesQueryHookResult = ReturnType<typeof usePatientDevicesQuery>;
export type PatientDevicesLazyQueryHookResult = ReturnType<typeof usePatientDevicesLazyQuery>;

export function queryPatientDevices(client: Apollo.ApolloClient<unknown>, variables: PatientDevicesQueryVariables, options?: Omit<Apollo.QueryOptions<PatientDevicesQuery, PatientDevicesQueryVariables>, "query" | 'variables'>) {
  return client.query<PatientDevicesQuery, PatientDevicesQueryVariables>({
    query: PatientDevicesDocument,
    variables,
    ...options,
  });
}

export const PatientNotificationsDocument = gql`
    query PatientNotifications($patientID: UUID!) {
  patientByPatientID(patientID: $patientID) {
    ...PatientProfileAdmin
    notifications {
      __typename
      notificationID
      rule
      variation
      createdAt
    }
  }
}
    ${PatientProfileAdminFragmentDoc}`;

/**
 * __usePatientNotificationsQuery__
 *
 * To run a query within a React component, call `usePatientNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientNotificationsQuery({
 *   variables: {
 *      patientID: // value for 'patientID'
 *   },
 * });
 */
export function usePatientNotificationsQuery(baseOptions: Apollo.QueryHookOptions<PatientNotificationsQuery, PatientNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientNotificationsQuery, PatientNotificationsQueryVariables>(PatientNotificationsDocument, options);
      }
export function usePatientNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientNotificationsQuery, PatientNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientNotificationsQuery, PatientNotificationsQueryVariables>(PatientNotificationsDocument, options);
        }
export type PatientNotificationsQueryHookResult = ReturnType<typeof usePatientNotificationsQuery>;
export type PatientNotificationsLazyQueryHookResult = ReturnType<typeof usePatientNotificationsLazyQuery>;

export function queryPatientNotifications(client: Apollo.ApolloClient<unknown>, variables: PatientNotificationsQueryVariables, options?: Omit<Apollo.QueryOptions<PatientNotificationsQuery, PatientNotificationsQueryVariables>, "query" | 'variables'>) {
  return client.query<PatientNotificationsQuery, PatientNotificationsQueryVariables>({
    query: PatientNotificationsDocument,
    variables,
    ...options,
  });
}

export const PatientEmailsDocument = gql`
    query PatientEmails($patientID: UUID!) {
  patientByPatientID(patientID: $patientID) {
    ...PatientProfileAdmin
    emails {
      __typename
      userEmailID
      thirdPartyServiceID
      description
      createdAt
    }
  }
}
    ${PatientProfileAdminFragmentDoc}`;

/**
 * __usePatientEmailsQuery__
 *
 * To run a query within a React component, call `usePatientEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientEmailsQuery({
 *   variables: {
 *      patientID: // value for 'patientID'
 *   },
 * });
 */
export function usePatientEmailsQuery(baseOptions: Apollo.QueryHookOptions<PatientEmailsQuery, PatientEmailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientEmailsQuery, PatientEmailsQueryVariables>(PatientEmailsDocument, options);
      }
export function usePatientEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientEmailsQuery, PatientEmailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientEmailsQuery, PatientEmailsQueryVariables>(PatientEmailsDocument, options);
        }
export type PatientEmailsQueryHookResult = ReturnType<typeof usePatientEmailsQuery>;
export type PatientEmailsLazyQueryHookResult = ReturnType<typeof usePatientEmailsLazyQuery>;

export function queryPatientEmails(client: Apollo.ApolloClient<unknown>, variables: PatientEmailsQueryVariables, options?: Omit<Apollo.QueryOptions<PatientEmailsQuery, PatientEmailsQueryVariables>, "query" | 'variables'>) {
  return client.query<PatientEmailsQuery, PatientEmailsQueryVariables>({
    query: PatientEmailsDocument,
    variables,
    ...options,
  });
}

export const PatientCaringContactsDocument = gql`
    query PatientCaringContacts($patientID: UUID!) {
  patientByPatientID(patientID: $patientID) {
    ...PatientProfileAdmin
    caringContacts {
      __typename
      caringContactID
      caringContactsMedium
      templateKey
      thirdPartyServiceID
      createdAt
    }
  }
}
    ${PatientProfileAdminFragmentDoc}`;

/**
 * __usePatientCaringContactsQuery__
 *
 * To run a query within a React component, call `usePatientCaringContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientCaringContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientCaringContactsQuery({
 *   variables: {
 *      patientID: // value for 'patientID'
 *   },
 * });
 */
export function usePatientCaringContactsQuery(baseOptions: Apollo.QueryHookOptions<PatientCaringContactsQuery, PatientCaringContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientCaringContactsQuery, PatientCaringContactsQueryVariables>(PatientCaringContactsDocument, options);
      }
export function usePatientCaringContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientCaringContactsQuery, PatientCaringContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientCaringContactsQuery, PatientCaringContactsQueryVariables>(PatientCaringContactsDocument, options);
        }
export type PatientCaringContactsQueryHookResult = ReturnType<typeof usePatientCaringContactsQuery>;
export type PatientCaringContactsLazyQueryHookResult = ReturnType<typeof usePatientCaringContactsLazyQuery>;

export function queryPatientCaringContacts(client: Apollo.ApolloClient<unknown>, variables: PatientCaringContactsQueryVariables, options?: Omit<Apollo.QueryOptions<PatientCaringContactsQuery, PatientCaringContactsQueryVariables>, "query" | 'variables'>) {
  return client.query<PatientCaringContactsQuery, PatientCaringContactsQueryVariables>({
    query: PatientCaringContactsDocument,
    variables,
    ...options,
  });
}
