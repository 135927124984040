import { defineMessages } from 'react-intl';

export const beckSuicide = defineMessages({
  question0_choice0: {
    id: 'Assessment_beckSuicide_question0_choice0',
    defaultMessage: 'I have a moderate to strong wish to live.',
  },
  question0_choice1: {
    id: 'Assessment_beckSuicide_question0_choice1',
    defaultMessage: 'I have a weak wish to live.',
  },
  question0_choice2: {
    id: 'Assessment_beckSuicide_question0_choice2',
    defaultMessage: 'I have no wish to live.',
  },
  question1_choice0: {
    id: 'Assessment_beckSuicide_question1_choice0',
    defaultMessage: 'I have no wish to die.',
  },
  question1_choice1: {
    id: 'Assessment_beckSuicide_question1_choice1',
    defaultMessage: 'I have a weak wish to die.',
  },
  question1_choice2: {
    id: 'Assessment_beckSuicide_question1_choice2',
    defaultMessage: 'I have a moderate to strong wish to die.',
  },
  question2_choice0: {
    id: 'Assessment_beckSuicide_question2_choice0',
    defaultMessage: 'My reasons for living outweigh my reasons for dying.',
  },
  question2_choice1: {
    id: 'Assessment_beckSuicide_question2_choice1',
    defaultMessage: 'My reasons for living or dying are about equal.',
  },
  question2_choice2: {
    id: 'Assessment_beckSuicide_question2_choice2',
    defaultMessage: 'My reasons for dying outweigh my reasons for living.',
  },
  question3_choice0: {
    id: 'Assessment_beckSuicide_question3_choice0',
    defaultMessage: 'I have no desire to kill myself.',
  },
  question3_choice1: {
    id: 'Assessment_beckSuicide_question3_choice1',
    defaultMessage: 'I have a weak desire to kill myself.',
  },
  question3_choice2: {
    id: 'Assessment_beckSuicide_question3_choice2',
    defaultMessage: 'I have a moderate to strong desire to kill myself.',
  },
  question4_choice0: {
    id: 'Assessment_beckSuicide_question4_choice0',
    defaultMessage:
      'I would try to save my life if I found myself in a life-threatening situation.',
  },
  question4_choice1: {
    id: 'Assessment_beckSuicide_question4_choice1',
    defaultMessage:
      'I would take a chance on life or death if I found myself in a life threatening situation.',
  },
  question4_choice2: {
    id: 'Assessment_beckSuicide_question4_choice2',
    defaultMessage:
      'I would not take the steps necessary to avoid death if I found myself in a life threatening situation.',
  },
  question5_choice0: {
    id: 'Assessment_beckSuicide_question5_choice0',
    defaultMessage: 'I have brief periods of thinking about killing myself which pass quickly.',
  },
  question5_choice1: {
    id: 'Assessment_beckSuicide_question5_choice1',
    defaultMessage:
      'I have periods of thinking about killing myself which last for moderate amounts of time.',
  },
  question5_choice2: {
    id: 'Assessment_beckSuicide_question5_choice2',
    defaultMessage: 'I have long periods of thinking about killing myself.',
  },
  question6_choice0: {
    id: 'Assessment_beckSuicide_question6_choice0',
    defaultMessage: 'I rarely or only occasionally think about killing myself.',
  },
  question6_choice1: {
    id: 'Assessment_beckSuicide_question6_choice1',
    defaultMessage: 'I have frequent thoughts about killing myself.',
  },
  question6_choice2: {
    id: 'Assessment_beckSuicide_question6_choice2',
    defaultMessage: 'I continuously think about killing myself.',
  },
  question7_choice0: {
    id: 'Assessment_beckSuicide_question7_choice0',
    defaultMessage: 'I do not accept the idea of killing myself.',
  },
  question7_choice1: {
    id: 'Assessment_beckSuicide_question7_choice1',
    defaultMessage: 'I neither accept nor reject the idea of killing myself.',
  },
  question7_choice2: {
    id: 'Assessment_beckSuicide_question7_choice2',
    defaultMessage: 'I accept the idea of killing myself.',
  },
  question8_choice0: {
    id: 'Assessment_beckSuicide_question8_choice0',
    defaultMessage: 'I can keep myself from committing suicide.',
  },
  question8_choice1: {
    id: 'Assessment_beckSuicide_question8_choice1',
    defaultMessage: 'I am unsure that I can keep myself from committing suicide.',
  },
  question8_choice2: {
    id: 'Assessment_beckSuicide_question8_choice2',
    defaultMessage: 'I cannot keep myself from committing suicide.',
  },
  question9_choice0: {
    id: 'Assessment_beckSuicide_question9_choice0',
    defaultMessage:
      'I would not kill myself because of my family, friends, religion, possible injury from an unsuccessful attempt, etc.',
  },
  question9_choice1: {
    id: 'Assessment_beckSuicide_question9_choice1',
    defaultMessage:
      'I am somewhat concerned about killing myself because of my family, friends, religion, possible injury from an unsuccessful attempt, etc.',
  },
  question9_choice2: {
    id: 'Assessment_beckSuicide_question9_choice2',
    defaultMessage:
      'I am not or only a little concerned about killing myself because of my family, friends, religion, possible injury from an unsuccessful attempt, etc.',
  },
  question10_choice0: {
    id: 'Assessment_beckSuicide_question10_choice0',
    defaultMessage:
      'My reasons for wanting to commit suicide are primarily aimed at influencing other people, such as getting even with people, making people happier, making people pay attention to me, etc.',
  },
  question10_choice1: {
    id: 'Assessment_beckSuicide_question10_choice1',
    defaultMessage:
      'My reasons for wanting to commit suicide are not only aimed at influencing other people, but also represent a way of solving my problems.',
  },
  question10_choice2: {
    id: 'Assessment_beckSuicide_question10_choice2',
    defaultMessage:
      'My reasons for wanting to commit suicide are primarily based upon escaping from my problems.',
  },
  question11_choice0: {
    id: 'Assessment_beckSuicide_question11_choice0',
    defaultMessage: 'I have no specific plan about how to kill myself.',
  },
  question11_choice1: {
    id: 'Assessment_beckSuicide_question11_choice1',
    defaultMessage: 'I have considered ways of killing myself but have not worked out the details.',
  },
  question11_choice2: {
    id: 'Assessment_beckSuicide_question11_choice2',
    defaultMessage: 'I have a specific plan for killing myself.',
  },
  question12_choice0: {
    id: 'Assessment_beckSuicide_question12_choice0',
    defaultMessage: 'I do not have access to a method or an opportunity to kill myself.',
  },
  question12_choice1: {
    id: 'Assessment_beckSuicide_question12_choice1',
    defaultMessage:
      'The method that I would use for committing suicide takes time, and I really do not have a good opportunity to use this method.',
  },
  question12_choice2: {
    id: 'Assessment_beckSuicide_question12_choice2',
    defaultMessage:
      'I have access or anticipate having access to the method that I would choose for killing myself and also have or shall have the opportunity to use it.',
  },
  question13_choice0: {
    id: 'Assessment_beckSuicide_question13_choice0',
    defaultMessage: 'I do not have the courage or the ability to commit suicide.',
  },
  question13_choice1: {
    id: 'Assessment_beckSuicide_question13_choice1',
    defaultMessage: 'I am unsure that I have the courage or the ability to commit suicide.',
  },
  question13_choice2: {
    id: 'Assessment_beckSuicide_question13_choice2',
    defaultMessage: 'I have the courage and the ability to commit suicide.',
  },
  question14_choice0: {
    id: 'Assessment_beckSuicide_question14_choice0',
    defaultMessage: 'I do not expect to make a suicide attempt.',
  },
  question14_choice1: {
    id: 'Assessment_beckSuicide_question14_choice1',
    defaultMessage: 'I am unsure that I shall make a suicide attempt.',
  },
  question14_choice2: {
    id: 'Assessment_beckSuicide_question14_choice2',
    defaultMessage: 'I am sure that I will make a suicide attempt.',
  },
  question15_choice0: {
    id: 'Assessment_beckSuicide_question15_choice0',
    defaultMessage: 'I have made no preparations for committing suicide.',
  },
  question15_choice1: {
    id: 'Assessment_beckSuicide_question15_choice1',
    defaultMessage: 'I have made some preparations for committing suicide.',
  },
  question15_choice2: {
    id: 'Assessment_beckSuicide_question15_choice2',
    defaultMessage: 'I have almost finished or completed my preparations for committing suicide.',
  },
  question16_choice0: {
    id: 'Assessment_beckSuicide_question16_choice0',
    defaultMessage: 'I have not written a suicide note.',
  },
  question16_choice1: {
    id: 'Assessment_beckSuicide_question16_choice1',
    defaultMessage:
      'I have thought about writing a suicide note or have started to write one, but have not completed it.',
  },
  question16_choice2: {
    id: 'Assessment_beckSuicide_question16_choice2',
    defaultMessage: 'I have completed a suicide note.',
  },
  question17_choice0: {
    id: 'Assessment_beckSuicide_question17_choice0',
    defaultMessage:
      'I have made no arrangements for what will happen after I have committed suicide.',
  },
  question17_choice1: {
    id: 'Assessment_beckSuicide_question17_choice1',
    defaultMessage:
      'I have thought about making arrangements for what will happen after I have committed suicide.',
  },
  question17_choice2: {
    id: 'Assessment_beckSuicide_question17_choice2',
    defaultMessage:
      'I have made definite arrangements for what will happen after I have committed suicide.',
  },
  question18_choice0: {
    id: 'Assessment_beckSuicide_question18_choice0',
    defaultMessage: 'I have not hidden my desire to kill myself from people.',
  },
  question18_choice1: {
    id: 'Assessment_beckSuicide_question18_choice1',
    defaultMessage: 'I have held back telling people about wanting to kill myself.',
  },
  question18_choice2: {
    id: 'Assessment_beckSuicide_question18_choice2',
    defaultMessage: 'I have attempted to hide, conceal, or lie about wanting to commit suicide.',
  },
  question19_choice0: {
    id: 'Assessment_beckSuicide_question19_choice0',
    defaultMessage: 'I have never attempted suicide.',
  },
  question19_choice1: {
    id: 'Assessment_beckSuicide_question19_choice1',
    defaultMessage: 'I have attempted suicide once.',
  },
  question19_choice2: {
    id: 'Assessment_beckSuicide_question19_choice2',
    defaultMessage: 'I have attempted suicide two or more times.',
  },
  question20_choice0: {
    id: 'Assessment_beckSuicide_question20_choice0',
    defaultMessage: 'My wish to die during the last suicide attempt was low.',
  },
  question20_choice1: {
    id: 'Assessment_beckSuicide_question20_choice1',
    defaultMessage: 'My wish to die during the last suicide attempt was moderate.',
  },
  question20_choice2: {
    id: 'Assessment_beckSuicide_question20_choice2',
    defaultMessage: 'My wish to die during the last suicide attempt was high.',
  },
});

export const scs = defineMessages({
  choice0: {
    id: 'Assessment_scs_choice0',
    defaultMessage: 'Strongly Disagree',
  },
  choice1: {
    id: 'Assessment_scs_choice1',
    defaultMessage: 'Disagree',
  },
  choice2: {
    id: 'Assessment_scs_choice2',
    defaultMessage: 'Neutral',
  },
  choice3: {
    id: 'Assessment_scs_choice3',
    defaultMessage: 'Agree',
  },
  choice4: {
    id: 'Assessment_scs_choice4',
    defaultMessage: 'Strongly Agree',
  },
  question0: {
    id: 'Assessment_scs_question0',
    defaultMessage: 'The world would be better off without me.',
  },
  question1: {
    id: 'Assessment_scs_question1',
    defaultMessage: 'Suicide is the only way to solve my problems.',
  },
  question2: { id: 'Assessment_scs_question2', defaultMessage: "I can't stand this pain anymore." },
  question3: {
    id: 'Assessment_scs_question3',
    defaultMessage: "I've never been successful at anything",
  },
  question4: {
    id: 'Assessment_scs_question4',
    defaultMessage: "I can't tolerate being this upset any longer.",
  },
  question5: {
    id: 'Assessment_scs_question5',
    defaultMessage: 'I can never be forgiven for the mistakes I have made.',
  },
  question6: {
    id: 'Assessment_scs_question6',
    defaultMessage: 'No one can help solve my problems.',
  },
  question7: {
    id: 'Assessment_scs_question7',
    defaultMessage: 'It is unbearable when I get this upset.',
  },
  question8: {
    id: 'Assessment_scs_question8',
    defaultMessage: 'I am completely unworthy of love.',
  },
  question9: {
    id: 'Assessment_scs_question9',
    defaultMessage: 'Nothing can help solve my problems.',
  },
  question10: {
    id: 'Assessment_scs_question10',
    defaultMessage: 'It is impossible to describe how badly I feel.',
  },
  question11: {
    id: 'Assessment_scs_question11',
    defaultMessage: "I can't cope with my problems any longer.",
  },
  question12: {
    id: 'Assessment_scs_question12',
    defaultMessage: "I can't imagine anyone being able to withstand this kind of pain.",
  },
  question13: {
    id: 'Assessment_scs_question13',
    defaultMessage: 'There is nothing redeeming about me.',
  },
  question14: {
    id: 'Assessment_scs_question14',
    defaultMessage: 'Suicide is the only way to end this pain.',
  },
  question15: {
    id: 'Assessment_scs_question15',
    defaultMessage: "I don't deserve to live another moment.",
  },
  question16: {
    id: 'Assessment_scs_question16',
    defaultMessage: 'I would rather die now than feel this unbearable pain.',
  },
  question17: { id: 'Assessment_scs_question17', defaultMessage: 'No one is as loathsome as me.' },
});

export const phq9 = defineMessages({
  choice0: {
    id: 'Assessment_phq9_choice0',
    defaultMessage: 'Not at all',
  },
  choice1: {
    id: 'Assessment_phq9_choice1',
    defaultMessage: 'Several days',
  },
  choice2: {
    id: 'Assessment_phq9_choice2',
    defaultMessage: 'More than half the days',
  },
  choice3: {
    id: 'Assessment_phq9_choice3',
    defaultMessage: 'Nearly every day',
  },
  question0: {
    id: 'Assessment_phq9_question0',
    defaultMessage: 'Little interest or pleasure in doing things',
  },
  question1: {
    id: 'Assessment_phq9_question1',
    defaultMessage: 'Feeling down, depressed, or hopeless',
  },
  question2: {
    id: 'Assessment_phq9_question2',
    defaultMessage: 'Trouble falling or staying asleep, or sleeping too much',
  },
  question3: {
    id: 'Assessment_phq9_question3',
    defaultMessage: 'Feeling tired or having little energy',
  },
  question4: { id: 'Assessment_phq9_question4', defaultMessage: 'Poor appetite or overeating' },
  question5: {
    id: 'Assessment_phq9_question5',
    defaultMessage:
      'Feeling bad about yourself or that you are a failure or have let yourself or your family down',
  },
  question6: {
    id: 'Assessment_phq9_question6',
    defaultMessage:
      'Trouble concentrating on things, such as reading the newspaper or watching television',
  },
  question7: {
    id: 'Assessment_phq9_question7',
    defaultMessage:
      'Moving or speaking so slowly that other people could have noticed. Or the opposite being so fidgety or restless that you have been moving around a lot more than usual',
  },
  question8: {
    id: 'Assessment_phq9_question8',
    defaultMessage: 'Thoughts that you would be better off dead, or of hurting yourself',
  },
});
