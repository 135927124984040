import { PlatformScreen } from '@src/components/PlatformScreen';
import { ListItemStyle, StaffTable, TableCell } from '@src/components/StaffTable';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { useI18n } from '@src/lib/i18n';
import { useTrialAssignmentsQuery } from '@src/screens/TrialAssignments.graphql.generated';

export function TrialAssignments() {
  const { data } = useTrialAssignmentsQuery();
  const { formatDate } = useI18n();

  return (
    <PlatformScreen
      crumbs={[{ label: 'Trial assignments', to: 'trial-assignments' }]}
      testID="TrialAssignments"
      disableScroll
    >
      <StaffTable
        columns={[
          { label: 'Name', grow: 2 },
          { label: 'Email', grow: 2 },
          { label: 'Trial Arm', grow: 0, initialWidth: 120 },
          'Created At',
        ]}
        loading={!data}
        testID="TrialAssignments_list"
        renderItem={({ item, index }) => {
          return (
            <View style={[ListItemStyle, index % 2 ? { backgroundColor: '#eee' } : {}]}>
              <TableCell grow={2}>
                <Text text={[item.givenName, item.familyName].join(' ')} weight="semibold" />
              </TableCell>
              <TableCell grow={2}>
                <Text text={item.email} />
              </TableCell>
              <TableCell grow={0} initialWidth={120}>
                <Text text={item.trialArmAssignment} />
              </TableCell>
              <TableCell>
                <Text
                  text={formatDate(new Date(item.createdAt), {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  })}
                />
              </TableCell>
            </View>
          );
        }}
        data={data?.productVariantAssignmentsForTrial ?? []}
        keyExtractor={(item, i) => item.email || i.toString()}
      />
    </PlatformScreen>
  );
}
